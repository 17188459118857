import React, { useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import useUsers from '../../context/UsersContext';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import useMenus from '../../context/MenuContext';
import vehiclePlanService from '../../service/vehiclePlanning.service';
import VehiclePlanFields from './VehiclePlanFields';
import VehiclePlanReports from './VehiclePlanReport';
import appService from '../../service/app.service';

export const VehiclePlanPage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const defaultValues = {
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    employeeId: null,
    vehicleId: null,
    duration: 1,
  };
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autoCompleteData, setAutoCompleteData] = useState({
    employees: [],
    vehicles: [],
  });

  useEffect(() => {
    setInitValues(defaultValues);
    if (!Object.keys(user).length) getUser();
    getMenus();
    getEmployees();
    getVehicles();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('vehiclePlan');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    employeeId: Yup.string().required(),
    vehicleId: Yup.string().required(),
    duration: Yup.number().nullable(),
  });

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const getVehicles = () => {
    appService.getVehicles().then((data) => {
      const formattedItems = data.map((item) => ({
        label: `${item.name}`,
        value: item.id,
      }));

      setAutoCompleteData((prevState) => ({
        ...prevState,
        vehicles: formattedItems,
      }));
    });
  };

  const getEmployees = () => {
    appService.getEmployees().then((data) => {
      const formattedItems = data.map((item) => ({
        label: `${item.name}`,
        value: item.id,
      }));

      setAutoCompleteData((prevState) => ({
        ...prevState,
        employees: formattedItems,
      }));
    });
  };

  const valuesToForm = () => {
    const { startDate, endDate, employeeId, vehicleId, duration } = selectedValues;

    setInitValues({
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      employeeId,
      vehicleId,
      duration,
    });
  };

  const handleInsertObject = (values) => {
    showLoader();
    const insertObject = {
      ...values,
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      createdBy: user.userId,
    };

    vehiclePlanService
      .createVehiclePlan(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel(data.id);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      updatedBy: user.userId,
    };

    vehiclePlanService.updateVehiclePlan(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    vehiclePlanService.deleteVehiclePlan(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-2 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, resetForm, setFieldValue, validateField }) => (
              <Form>
                <VehiclePlanFields
                  values={values}
                  errors={errors}
                  viewField={viewField}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                  selectedValues={selectedValues}
                  autoCompleteData={autoCompleteData}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-4 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <VehiclePlanReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autoCompleteData={autoCompleteData}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
