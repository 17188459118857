import { useEffect } from 'react';
import propertyService from '../../service/property.service';
import DynamicPropertyTable from './DynamicPropertyTable';

const DynamicPropertyMain = ({
  submenuId,
  disabled,
  selectedValues,
  canEdit,
  setDefaultValues,
  dynProperties,
  groupState = {},
  dynLoadingState,
}) => {
  const { dynProps, setDynProps } = dynProperties;
  const { setDynLoading } = dynLoadingState;

  useEffect(() => {
    if (submenuId > 0) {
      getFormProperties();
    }
  }, [submenuId]);

  const getFormProperties = () => {
    propertyService.getPropertiesByFormId(submenuId).then((data) => {
      if (!canEdit) {
        const teszt = data.filter(
          (filtered) => filtered.property.name !== 'Szerződött alap óradíj' && filtered.property.id !== 16
        );
        setDynProps(teszt);
      } else {
        setDynProps(data);
      }
      setDynLoading(false);
    });
  };

  return (
    <>
      {dynProps.length > 0 && (
        <DynamicPropertyTable
          rows={dynProps}
          disabled={disabled}
          selectedValues={selectedValues}
          setDefaultValues={setDefaultValues}
          groupState={groupState}
        />
      )}
    </>
  );
};

export default DynamicPropertyMain;
