import React from 'react';
import { ColorPicker } from '../../config/ColorPicker';

export const ProductionInfoPanel = ({ productionList }) => {
  const infoPanelBg =
    productionList.status && ColorPicker.find((color) => color.colorName === productionList.status.color);
  return (
    <div className="mt-5">
      <h2 className="text-2xl mb-2 text-labelColor">Művelet infó panel</h2>
      <div
        className="py-5 rounded-lg flex justify-around shadow-md"
        style={{
          background: infoPanelBg ? infoPanelBg?.colorBgCode : '#f3f4f6',
          color: infoPanelBg ? infoPanelBg?.textColor : '#607d8b',
        }}
      >
        <div>
          <p className="font-semibold text-lg">Késztermék neve</p>
          <p className="text-lg">{productionList.articleName}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">Késztermék cikkszáma</p>
          <p className="text-lg">{productionList.articleNumber}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">Művelet azonosító</p>
          <p className="text-lg">{productionList.operationIdlabel}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">Művelet neve</p>
          <p className="text-lg">{productionList.operationName}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">Tervezett idő</p>
          <p className="text-lg">
            {productionList && productionList.timeSpent ? productionList.timeSpent + ' óra' : ''}
          </p>
        </div>
        <div>
          <p className="font-semibold text-lg"> Tény idő</p>
          <p className="text-lg">
            {productionList && productionList.total?.timeSpent ? productionList.total?.timeSpent + ' óra' : ''}
          </p>
        </div>
      </div>
    </div>
  );
};
