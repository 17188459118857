import React, { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import _ from 'lodash';

import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useUsers from '../../context/UsersContext';
import attendenceService from '../../service/attendence.service';
import useMenus from '../../context/MenuContext';
import masterDataService from '../../service/masterData.service';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, ButtonUI, FormBase } from '../Interface';
import AttendanceField from './AttendanceFields';
import AttendanceReports from './AttendanceReports';
import AttendanceTabs from './AttendanceTabs';
import { generateAutocompleteItems } from '../../utils/helper';
import useParam from '../../context/ParamContext';

export const AttendancePage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [selectedRow, setSelectedRow] = useState({
    id: [],
  });
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    status: [],
    project: [],
    user: [],
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const defaultAttendanceUser = {
    id: null,
    isNew: true,
    userId: null,
    timeSpent: null,
    createdBy: user.userId,
  };

  const def = {
    date: dayjs(new Date()),
    projectId: null,
    statusId: null,
    attendanceUser: [],
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    getMenus();
    getParam();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('attendance');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const updateBatchedStatuses = (values) => {
    const updData = {
      statusId: values.statusId,
      selectedRows: selectedRow.id,
    };
    attendenceService.updateBatchedStatus(updData).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setSelectedRow({
        id: [],
      });
    });
  };

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          status: resp[0],
          project: resp[1],
          user: resp[2],
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
          user: generateAutocompleteItems(aData.user, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const valuesToForm = () => {
    const { date, projectId, statusId, orderNumber, attendanceUser } = selectedValues;

    setInitValues({
      date: dayjs(date),
      projectId,
      statusId,
      orderNumber,
      attendanceUser: attendanceUser.map((au) => ({
        id: au.listId,
        userId: au.userId,
        attendanceId: au.id,
        timeSpent: au.timeSpent,
        createdBy: au.createdBy,
      })),
    });
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    projectId: Yup.string().required(),
    statusId: Yup.string().required(),
    orderNumber: Yup.string().nullable(),
    attendanceUser: Yup.array().of(
      Yup.object().shape({
        userId: Yup.string().required(),
        timeSpent: Yup.number().required().positive().max(24),
      })
    ),
  });

  const insertObject = (values) => {
    const insertObject = {
      ...values,
      date: dayjs(values.date).toISOString(),
      userId: user.userId,
      createdBy: user.userId,
    };

    attendenceService
      .createAttendence(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel(data.id);
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      date: dayjs(values.date).toISOString(),
      updatedBy: user.userId,
    };

    attendenceService.updateAttendence(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      insertObject(values, resetForm);
    }
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    attendenceService.deleteAttendence(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  return (
    <>
      <FormBase
        name="Attendance"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setInitValues(defaultValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, resetForm, validateField, setFieldValue }) => (
                  <Form>
                    <AttendanceField
                      viewField={viewField}
                      selectedValues={selectedValues}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      validateField={validateField}
                      selectedRow={selectedRow}
                      autocompleteOptions={autocompleteOptions}
                    />
                    <AttendanceTabs
                      setRefetchTable={setRefetchTable}
                      selectedValues={selectedValues}
                      user={user}
                      disabled={viewField}
                      params={params}
                      defaultField={defaultAttendanceUser}
                      autocompleteOptions={autocompleteOptions}
                    />
                    {selectedRow.id.length === 0 && (
                      <FormButtonPanelUI
                        onCancelClick={() => {
                          setViewField(true);
                          setEditButtonvisible(true);
                          setAddField(true);
                          resetForm();
                        }}
                        disabled={editButtonVisible && addField}
                      />
                    )}
                    {selectedRow.id.length > 0 && (
                      <div className="flex justify-center">
                        <ButtonUI
                          className="bg-success"
                          text="rögzítés"
                          onClick={() => {
                            updateBatchedStatuses(values);
                          }}
                        />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            <AttendanceReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              selectedRows={{ selectedRow, setSelectedRow }}
              defaultValues={defaultValues}
              editButtonVisible={editButtonVisible}
              autocompleteOptions={autocompleteOptions}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            />
          </>
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
