import React from 'react';
import { useProduction } from '../../../hooks/useProduction';
import { ProductionPage } from './ProductionPage';
import ProductionReports from './ProductionReports';
import { FormBase } from '../../Interface';

const ProductionMain = () => {
  const productionState = useProduction();
  return (
    <FormBase
      name="Production"
      leftPanelContent={<ProductionPage productionState={productionState} />}
      rightPanelContent={<ProductionReports productionState={productionState} />}
    />
  );
};

export default ProductionMain;
