import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'sourcelist';

const sourcelistService = {
  getSourcelists: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/sourcelists`,
      data,
    });
  },

  updateSourcelist: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  createSourcelist: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteSourcelist: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  getSourcelistById: (id) => {
    return request({
      resource: `${baseURL}/${id}`,
    });
  },

  getSourcelistByItemCode: (itemCode) => {
    return request({
      resource: `${baseURL}/itemcode/${itemCode}`,
    });
  },

  createOperationMachine: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/operationmachine`,
      data,
    });
  },

  updateOperationMachine: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/operationmachine`,
      data,
    });
  },

  deleteOperationMachine: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/operationmachine/${id}`,
      data,
    });
  },
};
export default sourcelistService;
