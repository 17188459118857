import React from 'react';
import { useCrmTask } from '../../hooks/useCrmTask';
import { CrmTaskPage } from './CrmTaskPage';
import CrmTaskReports from './CrmTaskReports';
import { FormBase } from '../Interface';

export const CrmTaskMain = () => {
  const crmTaskState = useCrmTask();

  return (
    <FormBase
      name="crmTask"
      leftPanelContent={<CrmTaskPage crmTaskState={crmTaskState} />}
      rightPanelContent={<> {!crmTaskState.isLoading && <CrmTaskReports crmTaskState={crmTaskState} />}</>}
    />
  );
};
