import request from '../utils/Request';

const baseURL = 'masterdata';

const masterDataService = {
  typeToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/type/${subMenuId}`,
    });
  },

  statusToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/status/${subMenuId}`,
    });
  },

  priorityToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/priority/${subMenuId}`,
    });
  },

  groupToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/projectgroup/${subMenuId}`,
    });
  },

  classificationToForm: (subMenuId) => {
    return request({
      resource: `${baseURL}/classification/${subMenuId}`,
    });
  },

  companyToForm: () => {
    return request({
      resource: `${baseURL}/companies`,
    });
  },

  projectToForm: () => {
    return request({
      resource: `${baseURL}/projects`,
    });
  },

  usersToForm: () => {
    return request({
      resource: `${baseURL}/users`,
    });
  },

  resourceOneToForm: () => {
    return request({
      resource: `${baseURL}/resourceOne`,
    });
  },

  operationToForm: () => {
    return request({
      resource: `${baseURL}/operations`,
    });
  },

  quantityUnitToForm: () => {
    return request({
      resource: `${baseURL}/quantityUnits`,
    });
  },

  formTypeSubmenus: () => {
    return request({
      resource: `${baseURL}/formTypeSubmenus`,
    });
  },

  provinceToForm: () => {
    return request({
      resource: `${baseURL}/provinces`,
    });
  },

  resourceTwoToForm: () => {
    return request({
      resource: `${baseURL}/resourceTwo`,
    });
  },

  resourceThreeToForm: () => {
    return request({
      resource: `${baseURL}/resourceThree`,
    });
  },

  resourceFourToForm: () => {
    return request({
      resource: `${baseURL}/resourceFour`,
    });
  },

  resourceFiveToForm: () => {
    return request({
      resource: `${baseURL}/resourceFive`,
    });
  },

  bussinessPartnersToForm: () => {
    return request({
      resource: `${baseURL}/bussinessPartners`,
    });
  },

  itemsToForm: () => {
    return request({
      resource: `${baseURL}/items`,
    });
  },

  companyLocationToForm: () => {
    return request({
      resource: `${baseURL}/companylocation`,
    });
  },

  crmEventsToForm: () => {
    return request({
      resource: `${baseURL}/crmEvents`,
    });
  },
};

export default masterDataService;
