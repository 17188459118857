import React, { useEffect, useState } from 'react';
import { DialogUI } from '../Interface';
import { useCrmTask } from '../../hooks/useCrmTask';
import { CrmTaskPage } from './CrmTaskPage';

const CrmTaskDialog = ({ open, handleClose, setTaskChanged, setSelectedRow, task, parentData }) => {
  const crmTaskState = useCrmTask();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (Object.keys(task).length > 0) {
      crmTaskState.setSelectedValues(task);
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
      if (parentData && Object.keys(parentData).length > 0) {
        crmTaskState.setSelectedValues(parentData);
      }
    }
  }, [task, crmTaskState, parentData]);

  const onClose = () => {
    handleClose();
    if (typeof setSelectedRow === 'function') {
      setSelectedRow({});
    }
  };

  const parentId = parentData && Object.keys(parentData).length > 0 ? parentData?.id : task?.parentId;

  return (
    <DialogUI
      open={open}
      onClose={onClose}
      headerContent="CRM Esemény"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <CrmTaskPage
          dialog={{
            handleClose: handleClose,
            isUpdate: isUpdate,
            parentId: parentId,
            setDataChanged: setTaskChanged,
          }}
          crmTaskState={crmTaskState}
        />
      </div>
    </DialogUI>
  );
};

export default CrmTaskDialog;
