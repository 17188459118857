import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'checkList';

const checkListService = {
  getCheckListTemplates: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/templates`,
      data,
    });
  },

  createCheckListTemplate: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateCheckListTemplate: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteCheckListTemplate: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  createCheckList: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/checkList`,
      data,
    });
  },

  updateCheckList: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/checkList/${id}`,
      data,
    });
  },

  updateStatus: (id, checked) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/checkedStatus/${id}`,
      data: { checked: checked },
    });
  },

  deleteCheckList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/checkList/${id}`,
      data,
    });
  },

  getAllTemplate: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/checkListTemplates`,
    });
  },
};

export default checkListService;
