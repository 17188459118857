import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import kanbanService from '../../service/kanban.service';
import { useLoader } from '../../provider/LoaderProvider';

import useParam from '../../context/ParamContext';
import useUsers from '../../context/UsersContext';
import {
  ViewType,
  KanbanType,
  conditions,
  KanbanFilterFormState,
  getKanbanFooterTitle,
  FunnelViewType,
} from '../../config/kanban';
import { generateFilter } from '../../utils/helper';
import { ColorPicker } from '../../config/ColorPicker';
import useSaveView from '../../hooks/useSaveView';
import useView from '../../hooks/useView';
import Card from './Card';
import { AutoCompleteSelectUI, ButtonUI, DebouncedInputUI, FormLabelUI, SelectUI, TooltipUI } from '../Interface';
import Dialog from './Dialog';
import MessageDialog from './MessageDialog';

const KanbanView = ({ funnelView }) => {
  const { getUser, user } = useUsers();
  const location = useLocation();
  const saveView = useSaveView(user);
  const kanbanFilter = useView('kanbanFilter', user);
  const { getParam, params } = useParam();
  const { showLoader, hideLoader } = useLoader();
  const [kanbanData, setKanbanData] = useState([]);
  const [item, setItem] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [filterFormState, setFilterFormState] = useState({});
  const [loading, setLoading] = useState(null);
  const [type, setType] = useState('');
  const [defaultFilterFields, setDefaultFilterFields] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [taskChanged, setTaskChanged] = useState(false);
  const [messageData, setMessageData] = useState({
    addressedTo: null,
    eventData: {},
  });
  const [refetch, setRefetch] = useState(false);

  const [query, setQuery] = useState({
    filter: [],
  });

  useEffect(() => {
    if (type === '') {
      setType(KanbanType.TASK);
    }
    if (!Object.keys(user).length) getUser();
    getParam();
  }, []);

  useEffect(() => {
    if (location.state?.kanbanType) {
      setType(location.state.kanbanType);
    }
  }, [location.state]);

  useEffect(() => {
    if (!location.state?.kanbanType && Object.keys(kanbanFilter).length > 0) {
      if (funnelView && kanbanFilter?.funnelViewType) {
        setType(kanbanFilter.funnelViewType);
      } else if (!funnelView && kanbanFilter.viewType) {
        setType(kanbanFilter.viewType);
      }
    }
  }, [kanbanFilter, funnelView, location.state]);

  useEffect(() => {
    if (taskChanged) {
      getItemsByType();
      setTaskChanged(false);
    }
  }, [taskChanged]);

  const kanbanViewAccess =
    params.KANBAN_VIEW_ACCESS && params.KANBAN_VIEW_ACCESS.split(',').map((access) => parseInt(access));

  const loadResponsiblePersonValue = params.KANBAN_RESPONSIBLE_PERSON_DEFAULT;

  const hasAccess = kanbanViewAccess && kanbanViewAccess.includes(user.userId) ? 'Y' : 'N';

  useEffect(() => {
    if (type) {
      setFilterFormState((prev) => ({
        ...KanbanFilterFormState[type],
        ...(type === KanbanType.TASK &&
          (loadResponsiblePersonValue === 'Y' || loadResponsiblePersonValue === undefined) && {
            resposiblePersonId: user.userId,
          }),
      }));
    }
  }, [type, user.userId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const currentConditions = conditions[type || KanbanType.TASK];
    const newFilter = generateFilter(filterFormState, currentConditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
    setDefaultFilterFields(defaultFilter);
  }, [filterFormState]);

  useEffect(() => {
    if (refetch) {
      getItemsByType();
    }
  }, [refetch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (defaultFilterFields.length > 0 && query.filter.length > 0) {
        getItemsByType();
      }
    }, 100);

    return () => clearTimeout(delayDebounceFn);
  }, [query, defaultFilterFields.length]);

  const refreshKanbanBoard = params.KANBAN_BOARD_TIMED_UPDATE && Number(params.KANBAN_BOARD_TIMED_UPDATE);

  useEffect(() => {
    if (
      query &&
      type &&
      hasAccess &&
      refreshKanbanBoard &&
      typeof refreshKanbanBoard === 'number' &&
      refreshKanbanBoard > 0
    ) {
      const intervalId = setInterval(getItemsByType, refreshKanbanBoard);

      return () => clearInterval(intervalId);
    }
  }, [query, type, hasAccess]);

  const excludeViews = params.KANBAN_EXCLUDE_VIEWS ? JSON.parse(params.KANBAN_EXCLUDE_VIEWS) : [];

  const filteredViewType = useMemo(() => {
    return ViewType.filter((view) => !excludeViews.some((exclude) => exclude.viewName === view.value));
  }, [excludeViews]);

  const getItemsByType = () => {
    showLoader();
    kanbanService
      .getItemsByType(type, query, hasAccess)
      .then((data) => {
        setKanbanData(data);
        setRefetch(false);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getItemById = (id) => {
    showLoader();
    kanbanService
      .getItemById(type, id)
      .then((data) => {
        setItem(data);
        setDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleDropAndStatusUpdate = (statusId, kanbanItem) => {
    setLoading(kanbanItem.id);
    const newStatus = kanbanData.find((kanbanColumn) => kanbanColumn.itemId === statusId);

    const updatedColumn = {
      ...kanbanItem,
      statusId: statusId,
      status: {
        ...kanbanItem.status,
        itemName: newStatus.itemName,
        color: newStatus.color,
        statusId: newStatus.itemId,
      },
    };

    setKanbanData((prev) => {
      const updatedKanbanData = prev.map((kanbanColumn) => {
        if (kanbanColumn.itemId === statusId) {
          return {
            ...kanbanColumn,
            items: [updatedColumn, ...kanbanColumn.items],
          };
        }
        if (kanbanColumn.itemId === kanbanItem.statusId) {
          return {
            ...kanbanColumn,
            items: kanbanColumn.items.filter((t) => t.id !== kanbanItem.id),
          };
        }
        return kanbanColumn;
      });
      return updatedKanbanData;
    });

    kanbanService.updateItemsByType(type, kanbanItem.id, statusId, updatedColumn).finally(() => {
      setLoading(null);
    });
  };

  const onDragStart = (event, kanbanItem) => {
    event.dataTransfer.setData('kanbanItem', JSON.stringify(kanbanItem));

    if (funnelView) {
      const dragImage = event.target.cloneNode(true);
      dragImage.style.position = 'absolute';
      dragImage.style.opacity = '1';
      dragImage.style.pointerEvents = 'none';
      dragImage.style.transform = 'translateY(-50%)';
      document.body.appendChild(dragImage);
      event.dataTransfer.setDragImage(dragImage, 0, 0);

      setTimeout(() => {
        document.body.removeChild(dragImage);
      }, 0);
    }
  };

  const showMessageDialogParam = params.KANBAN_SHOW_MESSAGE_DIALOG ? JSON.parse(params.KANBAN_SHOW_MESSAGE_DIALOG) : [];

  const showMessageDialog =
    showMessageDialogParam && showMessageDialogParam.find((dialogPr) => dialogPr.kanbanType.includes(type));

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, statusId) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    const kanbanItem = JSON.parse(event.dataTransfer.getData('kanbanItem'));

    if (kanbanItem.statusId !== statusId) {
      handleDropAndStatusUpdate(statusId, kanbanItem);
      if (!funnelView && showMessageDialog) {
        setMessageDialogOpen(true);
        setMessageData((prev) => ({
          ...prev,
          eventData: kanbanItem,
        }));
      }
    }
  };

  const calcWidth = (index) => {
    if (!funnelView) return 'auto';
    if (index === 0) return 'calc(100% - 30px)';
    return windowWidth < 1400 ? `calc(100% - ${150 * index}px)` : `calc(100% - ${200 * index}px)`;
  };

  return (
    <div className="bg-gray-100 relative pt-1 w-full px-3 pb-3" style={{ minHeight: 'calc(100vh - 65px)' }}>
      <div className="fixed top-2 right-36  mt-1 z-40">
        <ButtonUI text="Frissítés" className="bg-success" onClick={getItemsByType} />
      </div>
      <div
        className={`md:left-80 z-40 ${funnelView ? 'fixed top-20 md:top-2 left-5' : 'absolute top-5 left-5 md:-top-14'}`}
      >
        <div className="flex flex-col md:flex-row w-full md:gap-10">
          <div className="w-full md:w-[150px]">
            <SelectUI
              label={<FormLabelUI text="Nézet" />}
              option={funnelView ? FunnelViewType : filteredViewType}
              value={type}
              onChange={(e) => {
                const value = e.target.value;
                setType(value);
                saveView('kanbanFilter', funnelView ? { funnelViewType: value } : { viewType: value });
              }}
            />
          </div>
          <div>
            {conditions[type]?.like?.map((fieldName) => (
              <DebouncedInputUI
                key={fieldName}
                label={<FormLabelUI text="Név" />}
                debounceMs={800}
                fieldName={fieldName}
                setQuickFilterSearchValue={(fieldName, newValue) => {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                  }));
                }}
                quickFilterSearchValue={filterFormState[fieldName]}
              />
            ))}
          </div>
          <div className={`${type !== KanbanType.TASK ? 'hidden' : 'w-[250px]'}`}>
            <AutoCompleteSelectUI
              variant="standard"
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prev) => ({
                    ...prev,
                    resposiblePersonId: '',
                  }));
                } else {
                  setFilterFormState((prev) => ({
                    ...prev,
                    resposiblePersonId: newVal.value,
                  }));
                }
              }}
              selectedValue={filterFormState.resposiblePersonId}
              selectedLabelValue={user?.userName}
              label={<FormLabelUI text="Felelős" />}
              table={'WebUser'}
              listType={{ id: 'userId', name: 'userName' }}
              conditions={[`WebUser.archive = 'N'`]}
            />
          </div>
        </div>
      </div>
      <div className={`${!funnelView ? ' overflow-x-auto w-full custom-scrollbar' : ''}`}>
        <div
          className={`flex flex-col justify-center w-full mt-4 pt-40 md:pt-0 ${funnelView ? 'md:items-center' : 'md:flex-row md:items-start md:flex-gow gap-5'}`}
          style={{ minWidth: !funnelView ? 'max-content' : '' }}
        >
          {kanbanData.map((kanban, index) => {
            const statusColor = ColorPicker.find((col) => col.colorName === kanban.color);
            return (
              <div
                key={kanban.itemId}
                className={`flex ${funnelView ? 'flex-row items-center' : 'flex-col basis-1/4 text-labelColor relative'}`}
                style={{
                  width: calcWidth(index),
                  minWidth: !funnelView ? '350px' : '',
                  maxWidth: !funnelView ? '350px' : '',
                }}
              >
                {funnelView && (
                  <div
                    className="text-center font-bold p-2 min-w-[230px] max-w-[230px] mr-2 relative -left-3 truncate"
                    style={{ color: statusColor?.textColor }}
                  >
                    <div className="-mt-2 truncate">
                      <TooltipUI title={kanban.itemName} className="cursor-pointer">
                        {kanban.itemName}
                      </TooltipUI>
                    </div>
                    <div>
                      <span className="text-5xl">{kanban.items.length}</span> db
                    </div>
                  </div>
                )}

                <div
                  onDragOver={(event) => onDragOver(event)}
                  onDrop={(event) => onDrop(event, kanban.itemId)}
                  style={{
                    minWidth: funnelView ? `calc(100% - 230px)` : 'auto',
                  }}
                >
                  {!funnelView && (
                    <div
                      className={`bg-white ${funnelView ? 'py-1' : 'py-4'} pl-2 text-center text-xl font-bold relative`}
                      style={{ borderBottom: `3px solid ${statusColor?.colorCode}` }}
                    >
                      {kanban.itemName}
                    </div>
                  )}
                  <div
                    className={`shadow-xl overflow-y-auto custom-scrollbar ${funnelView ? 'h-[140px]' : 'w-full h-[350px] pb-10 md:h-screen bg-kanbanBg p-3'}`}
                    style={{
                      maxHeight: 'calc(100vh - 205px)',
                      backgroundColor: funnelView ? statusColor?.kanbanBgColor : '',
                    }}
                  >
                    <div className={`${funnelView ? 'flex flex-wrap gap-x-2 gap-y-2 p-2' : ''}`}>
                      {kanban.items.map((data) => {
                        return (
                          <Card
                            type={type}
                            key={data.id}
                            data={data}
                            onDragStart={onDragStart}
                            getItemById={getItemById}
                            statusColor={statusColor}
                            params={params}
                            loading={loading === data.id ? loading : null}
                            funnelView={funnelView}
                            user={user}
                            setRefetch={setRefetch}
                            showMessageDialogParam={showMessageDialogParam}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {!funnelView && (
                    <div className="absolute z-20 bg-kanbanBg bottom-0 w-full py-1 flex justify-center">
                      <p className="font-bold">
                        {getKanbanFooterTitle(type)}: {kanban.items.length}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        data={item}
        handleClose={() => {
          setDialogOpen(false);
          setItem({});
        }}
        type={type}
        params={params}
        changed={{ taskChanged, setTaskChanged }}
      />

      <MessageDialog
        open={messageDialogOpen}
        data={item}
        handleClose={() => {
          setMessageDialogOpen(false);
        }}
        type={type}
        params={params}
        message={{ messageData, setMessageData }}
        user={user}
        setRefetch={setRefetch}
      />
    </div>
  );
};

export default KanbanView;
