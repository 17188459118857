import { useState } from 'react';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { dataGridColumns } from '../../../utils/helper';

import {
  DataGridUI,
  DataGridIdentifiers,
  FormErrorUI,
  AutoCompleteSelectUI,
  CheckboxUI,
  DatePickerUI,
  AddButtonUi,
} from '../../Interface';

function EditToolbar(props) {
  const { setRowModesModel, disabled, push, defaultResourceOne } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultResourceOne.id = uuidv4();
    await push(defaultResourceOne);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultResourceOne.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `resourceOne.${values.resourceOne.length ?? 0}.resourceId`,
      `resourceOne.${values.resourceOne.length ?? 0}.leader`,
      `resourceOne.${values.resourceOne.length ?? 0}.educationDate`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
}

const ResourceOneTable = ({ params, disabled, defaultResourceOne, autoCompleteOptions }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.resourceOne.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `resourceOne.${valueIndex}.resourceId`,
      `resourceOne.${valueIndex}.leader`,
      `resourceOne.${valueIndex}.educationDate`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.resourceOne?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'resourceId',
        headerName: 'Név',
        flex: 1.5,
        editable: true,
        renderCell: (data) => {
          const findedResourceOne = autoCompleteOptions?.resourceOne.find(
            (item) => item.value === data.row?.resourceId
          );
          return findedResourceOne?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedResourceOne = autoCompleteOptions.resourceOne.find((item) => item.value === data.row.resourceId);

          return (
            <Field
              name={`resourceOne.${valueIndex}.resourceId`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.resourceOne?.[valueIndex]?.resourceId} />}
              selectedValue={values.resourceOne?.[valueIndex]?.resourceId}
              selectedLabelValue={findedResourceOne?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`resourceOne.${valueIndex}.resourceId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal.value ?? null,
                });
              }}
              dataset={autoCompleteOptions.resourceOne}
              onBlur={async () => await validateField(`resourceOne.${valueIndex}.resourceId`)}
            />
          );
        },
      },
      {
        field: 'leader',
        headerName: 'Vezető',
        editable: true,
        renderCell: (data) => <CheckboxUI checked={data.row?.leader === true} disabled />,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`resourceOne.${valueIndex}.leader`}
              component={CheckboxUI}
              helperText={<FormErrorUI message={errors?.resourceOne?.[valueIndex]?.leader} />}
              checked={data.row?.leader === true}
              variant="standard"
              onChange={(e) => {
                setFieldValue(`resourceOne.${valueIndex}.leader`, e.target.checked ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.checked,
                });
              }}
              onBlur={async () => await validateField(`resourceOne.${valueIndex}.leader`)}
            />
          );
        },
      },
      ...(params.PROJECT_RES_ONE_EDUCATION_DATE_FIELD === 'Y'
        ? [
            {
              field: 'educationDate',
              headerName: 'Okt. dátuma',
              flex: 0.5,
              sortable: false,
              editable: true,
              renderCell: (data) => {
                return data.row.educationDate ? dayjs(data.row.educationDate).format('YYYY.MM.DD.') : null;
              },
              renderEditCell: (data) => {
                const valueIndex = getValueIndex(values, data.id);
                return (
                  <Field
                    name={`resourceOne.${valueIndex}.educationDate`}
                    component={DatePickerUI}
                    helperText={<FormErrorUI message={errors?.resourceOne?.[valueIndex]?.educationDate} />}
                    variant="standard"
                    value={dayjs(data.row.educationDate)}
                    onChange={(newDate) => {
                      setFieldValue(`resourceOne.${valueIndex}.educationDate`, newDate ?? '');
                      data.api.setEditCellValue({
                        id: data.id,
                        field: data.field,
                        value: newDate ?? null,
                      });
                    }}
                    onBlur={async () => await validateField(`resourceOne.${valueIndex}.educationDate`)}
                  />
                );
              },
            },
          ]
        : []),
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 1,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="resourceOne">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.ResourceOneTable}
                sx={{ height: 400 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.resourceOne ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultResourceOne,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default ResourceOneTable;
