import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { dataGridColumns } from '../../utils/helper';
import { DataGridUI, DataGridIdentifiers, FormErrorUI, InputUI, AddButtonUi } from '../Interface';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    defaultField.createdAt = dayjs();
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    await validateField(`communication.${values.communication.length ?? 0}.message`);
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const ProjectCommunication = ({ user, disabled, defaultField }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.communication.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    await validateField(`communication.${valueIndex}.message`);
    if (errors?.communication?.[valueIndex]?.message) {
      return;
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'createdAt',
        headerName: 'Dátum.',
        flex: 0.5,
        valueGetter: (params) => dayjs(params.row.createdAt).format('YYYY-MM-DD HH:mm'),
      },
      {
        field: 'userName',
        headerName: 'Felhasználó',
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'message',
        headerName: 'Üzenet',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`communication.${valueIndex}.message`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.communication?.[valueIndex]?.message} />}
              onChange={(e) => {
                setFieldValue(`communication.${valueIndex}.message`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`communication.${valueIndex}.message`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isCurrentUserRow = row.userId === user.userId;
          if (!isCurrentUserRow) {
            return [];
          }

          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="communication">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.projectCommunication}
                sx={{ height: 380 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.communication ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default ProjectCommunication;
