import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';

import Yup from '../../utils/yup';
import statusService from '../../service/status.service';
import addNotification from '../../utils/addNotification';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import { NotificationType } from '../../config';
import { FormHeaderUI, FormButtonPanelUI } from '../Interface';
import ClassificationReports from './ClassificationReports';
import ClassificationFields from './ClassificationFields';
import ClassificationTabs from './ClassificationTabs';
import classificationService from '../../service/classification.service';

export const ClassificationPage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [classificationItems, setClassificationItems] = useState([]);
  const [canEdit, setCanEdit] = useState(true);

  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        setClassificationItems(selectedValues?.classificationItem ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        valuesToForm();
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('classification');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const defaultValues = {
    name: '',
    subMenuId: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    subMenuId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { name, subMenuId } = selectedValues;

    setInitValues({
      name,
      subMenuId,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    classificationService.updateClassification(updData, selectedValues.id).then((_status) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setSelectedValues({});
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
      valuesToForm();
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    classificationService
      .createClassification(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues({});
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setViewField(true);
        valuesToForm();
      })
      .finally(() => {
        //@TODO loader
      });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
              setClassificationItems([]);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <ClassificationFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />

                {addField && (
                  <ClassificationTabs
                    disabled={viewField}
                    classification={{ classificationItems, setClassificationItems }}
                    setRefetchTable={setRefetchTable}
                    viewField={viewField}
                    selectedValues={selectedValues}
                    user={user}
                  />
                )}

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <ClassificationReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
          />
        </div>
      </div>
    </div>
  );
};
