import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonUI, CheckboxUI } from '../../Interface';
import CheckListDialog from './CheckListDialog';
import checkListService from '../../../service/checkList.service';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';

const CheckLists = ({ checkLists, submenuId, user, lineId, setRefetchTable, disabled }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    setRows(checkLists);
  }, [checkLists]);

  useEffect(() => {
    getAllTemplate();
  }, []);

  const getAllTemplate = () => {
    checkListService.getAllTemplate().then((data) => {
      setTemplates(data);
    });
  };

  const handleCreateCheckList = (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    checkListService
      .createCheckList(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setIsDialogOpen(false);
        setRefetchTable(true);
        setRows((prev) => [...prev, data]);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const handleUpdateCheckList = (values) => {
    const updateObject = {
      ...values,
      updatedBy: user.userId,
    };

    checkListService
      .updateCheckList(updateObject, selectedChecklist)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setIsDialogOpen(false);
        setRefetchTable(true);

        setRows((prevRows) => prevRows.map((row) => (row.id === data.id ? { ...row, ...data } : row)));
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const handleDeleteCheckList = () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    checkListService.deleteCheckList(deleteData, selectedChecklist).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setRows(rows.filter((row) => row.id !== selectedChecklist));
    });
  };

  const changeCheckedStatus = async (id, checked) => {
    checkListService.updateStatus(id, checked).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          checkListItem: row.checkListItem.map((item) => (item.id === id ? { ...item, checked } : item)),
        }))
      );
      setRefetchTable(true);
    });
  };

  return (
    <div>
      <div className="mb-5">
        <ButtonUI
          text="Checklista módosítása"
          className="bg-success"
          disabled={disabled}
          onClick={() => setIsDialogOpen(true)}
        />
      </div>
      <div className="max-h-[300px] overflow-auto custom-scrollbar px-1">
        {rows.map((row) => {
          const checkedCount = row.checkListItem ? row.checkListItem.filter((item) => item.checked).length : 0;
          return (
            <Accordion key={row.id} className="mb-2">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {row.name}{' '}
                <span className="ml-2">({`${row.checkListItem ? row.checkListItem.length : 0}/${checkedCount}`})</span>
              </AccordionSummary>
              {row.checkListItem &&
                row.checkListItem.map((item) => (
                  <AccordionDetails key={item.id}>
                    <div className="-mb-4">
                      <CheckboxUI
                        label={item.name}
                        disabled={disabled}
                        checked={item.checked}
                        onChange={(e) => changeCheckedStatus(item.id, e.target.checked)}
                      />
                    </div>
                  </AccordionDetails>
                ))}
            </Accordion>
          );
        })}
      </div>
      <CheckListDialog
        rows={rows}
        open={isDialogOpen}
        handleCreate={handleCreateCheckList}
        handleUpdate={handleUpdateCheckList}
        handleDelete={handleDeleteCheckList}
        lineId={lineId}
        user={user}
        submenuId={submenuId}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        checkList={{ selectedChecklist, setSelectedChecklist }}
        templates={templates}
      />
    </div>
  );
};

export default CheckLists;
