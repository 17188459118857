import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../../Interface';

export const ResourceOneFields = ({ viewField, selectedValues, autocompleteOptions }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Erőforrás neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>
      <div className={selectedValues.generatedName ? 'col-span-4' : 'hidden'}>
        <Field
          type="text"
          name="generatedName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Összefűzött erőforrás név" />}
          variant="standard"
          disabled
          InputLabelProps={{ shrink: values.generatedName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="groupId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Erőforrás csoportja" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.groupId} />}
          onChange={(_e, newVal) => {
            setFieldValue('groupId', newVal?.value ?? null).then(() => {
              validateField('groupId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.groupId !== '' }}
          selectedValue={values.groupId}
          selectedLabelValue={selectedValues?.resourceGroup?.itemName}
          dataset={autocompleteOptions?.resourceGroup}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          dataset={autocompleteOptions?.status}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="typeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Típus" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.typeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('typeId', newVal?.value ?? null).then(() => {
              validateField('typeId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.typeId !== '' }}
          selectedValue={values.typeId}
          selectedLabelValue={selectedValues?.type?.itemName}
          dataset={autocompleteOptions?.type}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="active"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.active} />}
          onChange={(_e, newVal) => {
            setFieldValue('active', newVal?.value ?? null).then(() => {
              validateField('active');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.active !== '' }}
          selectedValue={values.active}
          table={'WebUserArchive'}
          listType={{ id: 'archiveId', name: 'arhiveName' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="classificationId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Besorolás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.classificationId} />}
          onChange={(_e, newVal) => {
            setFieldValue('classificationId', newVal?.value ?? null).then(() => {
              validateField('classificationId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.classificationId !== '' }}
          selectedValue={values.classificationId}
          selectedLabelValue={selectedValues?.classification?.name}
          dataset={autocompleteOptions?.classification}
        />
      </div>
    </div>
  );
};
