import { useState, useEffect, useMemo } from 'react';

import dayjs from 'dayjs';

import { dataGridPremiumColumns } from '../../utils/helper';
import attendenceService from '../../service/attendence.service';
import { DataGridIdentifiers, CheckboxUI, DatagridPremiumUi } from '../Interface';

const AttendanceReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  selectedRows,
  defaultValues,
  editButtonVisible,
  autocompleteOptions,
  rowSelection,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { selectedRow, setSelectedRow } = selectedRows;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'date', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled && selectedRow.id.length > 0) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    attendenceService.getAttendence(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'batchStatus',
        headerName: 'S',
        type: 'string',
        renderCell: (params) => {
          const isChecked = selectedRow.id?.includes(params.id);
          return (
            <CheckboxUI
              checked={isChecked}
              disabled={!editButtonVisible}
              onChange={() => {
                setRowSelectionModel([]);
                setSelectedValues(defaultValues);
                setSelectedRow((prev) => {
                  const isSelected = prev.id?.includes(params.id);

                  return {
                    ...prev,
                    id: isSelected ? prev.id.filter((itemId) => itemId !== params.id) : [...(prev.id || []), params.id],
                  };
                });
              }}
            />
          );
        },
        sortable: false,
        filterable: false,
      },
      {
        field: 'date',
        headerName: 'Dátum',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.date);
        },
        renderCell: (data) => {
          return dayjs(data.row.date).format('YYYY.MM.DD');
        },
      },
      {
        field: 'projectId',
        headerName: 'Projekt',
        type: 'singleSelect',
        sortable: false,
        renderCell: (data) => {
          return data.row.project?.projectName;
        },
        valueOptions: autocompleteOptions.project,
      },
      {
        field: 'statusId',
        headerName: 'Státusz',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.status?.itemName;
        },
        valueOptions: autocompleteOptions.status,
      },
    ]);
  }, [autocompleteOptions, selectedRow, editButtonVisible]);

  const handleCellClick = (param, event) => {
    if (param.field === 'batchStatus') {
      event.stopPropagation();
    }
  };

  return (
    <>
      {Object.keys(autocompleteOptions).length > 0 && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.attendance}
          datagridInitialState={initialState}
          dataLoader={getList}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          onRowClick={(rowData) => {
            if (!viewField || selectedRow.id.length > 0) return;
            setSelectedValues(rowData.row);
          }}
          paginationAndfilterMode="server"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick={!viewField || selectedRow.id.length > 0}
          onCellClick={handleCellClick}
        />
      )}
    </>
  );
};

export default AttendanceReports;
