import React, { useEffect, useState } from 'react';
import { useSimpleTask } from '../../hooks/useSimpleTask';
import { DialogUI } from '../Interface';
import { SimpleTaskForm } from '../NewSimpleTask/SimpleTaskForm';

const SimpleTaskDialog = ({ open, handleClose, task, setSelectedRow, setTaskChanged, selectedValues }) => {
  const simpleTaskState = useSimpleTask();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (Object.keys(task).length > 0) {
      simpleTaskState.setSelectedValues(task);
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [task, simpleTaskState]);

  const onClose = () => {
    handleClose();
    setSelectedRow({});
  };

  return (
    <DialogUI
      open={open}
      onClose={onClose}
      headerContent="Feladatok"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <SimpleTaskForm
          dialog={{
            handleClose: handleClose,
            isUpdate: isUpdate,
            setTaskChanged: setTaskChanged,
          }}
          simpleTaskState={simpleTaskState}
        />
      </div>
    </DialogUI>
  );
};

export default SimpleTaskDialog;
