import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import useSaveView from '../../hooks/useSaveView';
import { AutoCompleteSelectUI } from '../Interface';
import AddIcon from '@mui/icons-material/Add';
import useUsers from '../../context/UsersContext';

const GanttFilter = ({ ganttFilter, filter, setSimpleTaskDialogOpen, setCreateProductDialogOpen }) => {
  const { getUser, user } = useUsers();
  const saveView = useSaveView(user);
  const { filterFormState, setFilterFormState } = filter;

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    const newViewSettings = { ...ganttFilter, [name]: checked };
    saveView('ganttFilter', newViewSettings);
  };
  return (
    <div className="flex items-center">
      <div className="flex gap-5">
        <FormControlLabel
          className="text-white"
          control={
            <Checkbox
              name="production"
              checked={ganttFilter?.production || false}
              onChange={handleCheckboxChange}
              sx={{
                color: '#fff',
                '&.Mui-checked': {
                  color: '#fff',
                },
              }}
            />
          }
          label="Gyártás"
        />
        <FormControlLabel
          className="text-white text-lg"
          control={
            <Checkbox
              name="task"
              checked={ganttFilter?.task || false}
              onChange={handleCheckboxChange}
              sx={{
                color: '#fff',
                '&.Mui-checked': {
                  color: '#fff',
                },
              }}
            />
          }
          label="Feladatok"
        />
      </div>
      <div className="flex items-center">
        <div className="w-[200px]">
          <AutoCompleteSelectUI
            fullWidth
            className="ganttfilter relative -top-2 -left-2"
            table="production"
            label={<span className="text-white text-lg">Munkaszám</span>}
            listType={{ id: 'productionId', name: 'workingNumber' }}
            selectedValue={filterFormState.productionId}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  productionId: '',
                }));
              } else {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  productionId: newVal.value,
                }));
              }
            }}
            conditions={[`production.deletedBy IS NULL`]}
            sx={{
              input: {
                color: 'white',
              },
            }}
          />
        </div>
        <div className="w-[150px]">
          <AutoCompleteSelectUI
            fullWidth
            className="ganttfilter relative -top-2 left-5"
            label={<span className="text-white text-lg">Művelet</span>}
            table="operation"
            listType={{ id: 'operationId', name: 'operationName' }}
            selectedValue={filterFormState.operationId}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  operationId: '',
                }));
              } else {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  operationId: newVal.value,
                }));
              }
            }}
            conditions={[`operation.archive = 'N'`]}
            sx={{
              input: {
                color: 'white',
              },
            }}
          />
        </div>
        <div onClick={() => setSimpleTaskDialogOpen(true)} className="cursor-pointer">
          <div className="bg-white text-black text-sm font-medium relative left-14  py-2 px-5 rounded flex items-center justify-center gap-1">
            <AddIcon className="text-xl" />
            <span className="text-base">Karbantartás</span>
          </div>
        </div>
        <div onClick={() => setCreateProductDialogOpen(true)} className="cursor-pointer w-[120px]">
          <div className="bg-white text-black text-sm font-medium relative left-16  py-2 px-5 rounded flex items-center justify-center gap-1">
            <AddIcon className="text-xl" />
            <span className="text-base">Gyártás</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GanttFilter;
