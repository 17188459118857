import React, { useEffect, useState } from 'react';
import historyService from '../../service/history.service';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { dataGridColumns } from '../../utils/helper';
import dayjs from 'dayjs';

export const HistoryPage = () => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    getAllHistory();
  }, []);

  const getAllHistory = () => {
    historyService.getHistories().then((data) => {
      setHistory(data);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'formId',
      headerName: 'Form azonosító',
      flex: 0.2,
    },
    {
      field: 'userId',
      headerName: 'Módosító felhasználó',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return params.row?.user?.userName;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Módosítás dátuma',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return dayjs(params.row.updatedAt).format('YYYY.MM.DD. HH:mm');
      },
    },
    {
      field: 'oldValue',
      headerName: 'Régi érték',
      flex: 1,
    },
    {
      field: 'newValue',
      headerName: 'Új érték',
      flex: 1,
    },
  ]);

  return (
    <div className="h-screen">
      <DataGridPremium rows={history} columns={columns} />
    </div>
  );
};
