import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { dataGridColumns } from '../../utils/helper';
import { DataGridUI, DataGridIdentifiers, FormErrorUI, InputUI, AddButtonUi, AutoCompleteSelectUI } from '../Interface';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    await validateField(`simpleTaskRespPersonlist.${values.simpleTaskRespPersonlist.length ?? 0}.userId`);
    await validateField(`simpleTaskRespPersonlist.${values.simpleTaskRespPersonlist.length ?? 0}.roleName`);
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const SimpletaskResponsiblePerson = ({ disabled, defaultField, autocompleteOptions }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.simpleTaskRespPersonlist.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    await validateField(`simpleTaskRespPersonlist.${valueIndex}.userId`);
    await validateField(`simpleTaskRespPersonlist.${valueIndex}.roleName`);

    if (errors?.simpleTaskRespPersonlist?.[valueIndex]?.message) {
      return;
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'userId',
        headerName: 'Név',
        editable: true,
        sortable: false,
        flex: 1,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedUser = autocompleteOptions.user.find(
            (u) => u.value === values.simpleTaskRespPersonlist?.[valueIndex]?.userId
          );

          return findedUser?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedUser = autocompleteOptions.user.find(
            (u) => u.value === values.simpleTaskRespPersonlist?.[valueIndex]?.userId
          );
          return (
            <Field
              name={`simpleTaskRespPersonlist.${valueIndex}.userId`}
              type="text"
              variant="standard"
              as={AutoCompleteSelectUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.simpleTaskRespPersonlist?.[valueIndex]?.userId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`simpleTaskRespPersonlist.${valueIndex}.userId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`simpleTaskRespPersonlist.${valueIndex}.userId`)}
              value={findedUser}
              isOptionEqualToValue={(option, value) => option.value === value}
              dataset={autocompleteOptions.user}
            />
          );
        },
      },
      {
        field: 'roleName',
        headerName: 'Szerep',
        editable: true,
        sortable: false,
        flex: 1,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`simpleTaskRespPersonlist.${valueIndex}.roleName`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.simpleTaskRespPersonlist?.[valueIndex]?.roleName} />}
              onChange={(e) => {
                setFieldValue(`simpleTaskRespPersonlist.${valueIndex}.roleName`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`simpleTaskRespPersonlist.${valueIndex}.roleName`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };
  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="simpleTaskRespPersonlist">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.simpleTaskRespPersonlist}
                sx={{ height: 380 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.simpleTaskRespPersonlist ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default SimpletaskResponsiblePerson;
