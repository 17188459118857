import { useState, useEffect } from 'react';

import useUsers from '../context/UsersContext';
import useMenus from '../context/MenuContext';
import masterDataService from '../service/masterData.service';
import { generateAutocompleteItems } from '../utils/helper';

export const useProduction = () => {
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    status: [],
    priority: [],
  });
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [canEdit, setCanEdit] = useState(true);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [submenu, setSubmenu] = useState(null);

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('production');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .usersToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemsToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          status: resp[0],
          priority: resp[1],
          company: resp[2],
          user: resp[3],
          project: resp[4],
          resourceOne: resp[5],
          operation: resp[6],
          quantityUnit: resp[7],
          items: resp[8],
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          priority: generateAutocompleteItems(aData.priority.priorityListItems, 'itemName', 'itemId'),
          company: generateAutocompleteItems(aData.company, 'companyName', 'companyId'),
          user: generateAutocompleteItems(aData.user, 'userName', 'userId'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
          operation: generateAutocompleteItems(aData.operation, 'operationName', 'operationId'),
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'quantityUnitName', 'quantityUnitId'),
          items: generateAutocompleteItems(aData.items, ['itemCode', 'itemName'], 'itemCode'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  return {
    refetchTable,
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    rowSelectionModel,
    setRowSelectionModel,
    autocompleteOptions,
    setAutoCompleteOptions,
    menus,
    user,
    canEdit,
    autocompleteData,
  };
};
