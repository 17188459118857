import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import { ColorPicker } from '../../config/ColorPicker';
import EventChip from './EventChip';
import useView from '../../hooks/useView';
import useSaveView from '../../hooks/useSaveView';
import GanttFilter from './GanttFilter';
import employeeResourcePlannerService from '../../service/employeeResourcePlanner.service';
import { EmployeeGanttFilterType } from '../../config/gantt';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import { TypeDialog } from './TypeDialog';
import { generateFilter } from '../../utils/helper';

export const EmployeeResourcePlanner = () => {
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const saveView = useSaveView(user);
  const { menus, getMenus, getSubMenuNamesByUrls, getMenuSubMenuId } = useMenus();
  const ganttFilter = useView('ganttFilter', user);
  const checkboxFiltersRef = useRef(null);
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ resources: [], taskEvents: [], backgroundEvents: [] });
  const [dateRange, setDateRange] = useState({});
  const [type, setType] = useState('');
  const calendarRef = useRef(null);

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [query, setQuery] = useState({
    filter: [],
  });
  const [filterVal, setFilterVal] = useState({
    simpleTaskTypeId: [],
  });
  const [submenu, setSubmenu] = useState({});

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setTimeInterval(slotIntervals.day);
    setCalendarViews(calendarDayViews);

    getParam();
    getMenus();
    if (!Object.keys(user).length) getUser();

    const toolbarElement = document.querySelector('.fc-toolbar-chunk');
    if (toolbarElement && checkboxFiltersRef.current) {
      toolbarElement.appendChild(checkboxFiltersRef.current);
    }
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('simpleTask');
    setSubmenu(submenuId);
  }, [menus]);

  const conditions = {};

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];
    const newStatus = filterVal.simpleTaskTypeId.map((sIds) => sIds.value);
    const newFilterVal = { ...filterVal, simpleTaskTypeId: newStatus };
    const newFilter = generateFilter(newFilterVal, conditions);

    if (JSON.stringify(query.filter) !== JSON.stringify([...defaultFilter, ...newFilter])) {
      setQuery((prev) => ({
        ...prev,
        filter: [...defaultFilter, ...newFilter],
      }));
    }
  }, [filterVal]);

  useEffect(() => {
    if (ganttFilter && ganttFilter.selectedEmployeeValue) {
      setType(ganttFilter.selectedEmployeeValue);
    } else {
      setType(EmployeeGanttFilterType.ALL);
    }
  }, [ganttFilter]);

  useEffect(() => {
    if (type !== '' && type !== undefined) {
      getEmployeeByType();
    }
  }, [type]);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  useEffect(() => {
    if (
      Object.keys(query).length > 0 &&
      query.filter?.length > 0 &&
      dateRange.startDate &&
      dateRange.endDate &&
      type !== undefined
    ) {
      getTasks();
    }
  }, [query, dateRange, type]);

  useEffect(() => {
    if (tasks.resources.length > 0 && tasks.taskEvents.length > 0) {
      setIsDataLoaded(true);
    }
  }, [tasks.resources, tasks.taskEvents]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate && isDataLoaded) {
      const today = dayjs().startOf('day');
      const start = dateRange.startDate.isAfter(today) ? dateRange.startDate : today.add(1, 'day');
      const end = dateRange.endDate;

      checkMissingEventsInRange(start, end);
    }
  }, [dateRange, isDataLoaded, tasks.resources]);

  useEffect(() => {
    if (ganttFilter.employeeType && ganttFilter.employeeType !== filterVal.simpleTaskTypeId) {
      setFilterVal((prev) => ({ ...prev, simpleTaskTypeId: ganttFilter.employeeType }));
    }
  }, [ganttFilter.employeeType]);

  useEffect(() => {
    if (ganttFilter?.timeTableView && timeInterval) {
      setSelectedView(ganttFilter.timeTableView);

      const getSlotLabelFormat = () => {
        const view = ganttFilter.timeTableView;

        if (timeInterval.days === 1) {
          return calendarDayViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.weeks === 1) {
          return calendarWeekViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.months === 1) {
          return calendarMonthViews[view]?.slotLabelFormat || [];
        } else {
          return [];
        }
      };

      setCalendarViews((prevViews) => {
        const newViewConfig = {
          slotLabelFormat: getSlotLabelFormat(),
        };

        return {
          ...prevViews,
          [ganttFilter.timeTableView]: {
            ...prevViews[ganttFilter.timeTableView],
            ...newViewConfig,
          },
        };
      });
    }
  }, [ganttFilter?.timeTableView, timeInterval]);

  useEffect(() => {
    if (ganttFilter.timeTableTimeInterval) {
      setTimeInterval(ganttFilter.timeTableTimeInterval);
    }
  }, [ganttFilter.timeTableTimeInterval, selectedView]);

  useEffect(() => {
    if (ganttFilter?.timeTableView && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(ganttFilter.timeTableView);
    }
  }, [ganttFilter.timeTableView]);

  useEffect(() => {
    if (selectedView && timeInterval && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        setTimeout(() => {
          scrollToCurrentTimeUnit();
        }, 200);
      }
    }
  }, [selectedView, calendarRef, timeInterval]);

  const slotIntervals = {
    day: { days: 1 },
    month: { months: 1 },
    week: { weeks: 1 },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['projectResourcePlanner']);
  }, [menus]);

  const getEmployeeByType = () => {
    showLoader();
    employeeResourcePlannerService
      .getEmployeeByType(type)
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, resources: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const colorParam = params.EMPLOYEE_RESOURCE_PLANNER_CHIP_COLOR_THEME;
  const parsedParam = colorParam ? JSON.parse(colorParam) : [];

  const getTasks = () => {
    //showLoader();
    employeeResourcePlannerService
      .getTasks({ dateRange, query })
      .then((data) => {
        const taskEvents = data.map((task) => {
          const colorStyles = getColorStyles(task.eventData, parsedParam[0], 'EMPLOYEE');

          return {
            ...task,
            ...colorStyles,
          };
        });
        setTasks((prevState) => ({ ...prevState, taskEvents }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getColorStyles = (event, colorProperties, eventType) => {
    const defaultStyles = { backgroundColor: '#fff', textColor: '#000' };

    const colorProperty = colorProperties?.[eventType];
    if (!colorProperty) return defaultStyles;

    const colorName = colorProperty.split('.').reduce((obj, key) => obj?.[key], event);
    if (!colorName) return defaultStyles;

    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj?.colorBgCode || defaultStyles.backgroundColor,
      textColor: colorObj?.textColor || defaultStyles.textColor,
    };
  };

  const checkMissingEventsInRange = useCallback(
    (start, end) => {
      if (!calendarRef.current) return;
      const calendarApi = calendarRef.current.getApi();
      const allEvents = calendarApi.getEvents();

      const resourceEventDates = allEvents.reduce((acc, event) => {
        const resourceId = event.getResources()[0]?.id;
        if (!resourceId) return acc;

        const eventStart = dayjs(event.start).startOf('day');
        const eventEnd = dayjs(event.end).startOf('day');

        const eventDates = acc.get(resourceId) || new Set();

        let currentDate = eventStart;
        while (currentDate.isBefore(eventEnd) || currentDate.isSame(eventEnd, 'day')) {
          eventDates.add(currentDate.format('YYYY-MM-DD'));
          currentDate = currentDate.add(1, 'day');
        }

        acc.set(resourceId, eventDates);
        return acc;
      }, new Map());

      const startLimit = start;
      const maxEndDate = startLimit.add(91, 'day').endOf('day');
      const adjustedEndDate = end.isBefore(maxEndDate) ? end : maxEndDate;

      const missingEvents = tasks.resources.reduce((missing, resource) => {
        const resourceId = resource.id;
        const eventDates = resourceEventDates.get(resourceId) || new Set();

        let currentDate = startLimit;

        while (currentDate.isBefore(adjustedEndDate)) {
          const formattedDate = currentDate.format('YYYY-MM-DD');

          const isWeekend = currentDate.day() === 0 || currentDate.day() === 6;
          if (!isWeekend && !eventDates.has(formattedDate)) {
            missing.push({
              resourceId: resourceId,
              start: formattedDate,
              end: end,
              display: 'background',
              backgroundColor: '#f87171',
            });
          }

          currentDate = currentDate.add(1, 'day');
        }

        return missing;
      }, []);

      setTasks((prevState) => {
        if (JSON.stringify(prevState.backgroundEvents) !== JSON.stringify(missingEvents)) {
          return { ...prevState, backgroundEvents: missingEvents };
        }
        return prevState;
      });
    },
    [tasks.resources, dateRange]
  );

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (dateRange.startDate?.isSame(startDate, 'day') && dateRange.endDate?.isSame(endDate, 'day')) {
      return;
    }
    setDateRange({ startDate, endDate });

    if (selectedView !== dateInfo.view.type) {
      setSelectedView(dateInfo.view.type);

      if (Object.keys(ganttFilter).length > 0) {
        saveView('ganttFilter', { timeTableView: dateInfo.view.type });
      } else {
        return;
      }
    }
  };

  const timeIntervalButtons = {
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
        saveView('ganttFilter', { timeTableTimeInterval: slotIntervals.day });
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
        saveView('ganttFilter', { timeTableTimeInterval: slotIntervals.week });
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
        saveView('ganttFilter', { timeTableTimeInterval: slotIntervals.month });
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineMonth':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  const scrollToCurrentTimeUnit = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const today = dayjs();

      let timeUnit;
      let difference;

      switch (selectedView) {
        case 'resourceTimelineMonth':
          timeUnit = 'day';
          const startOfMonth = dayjs().startOf('month');
          difference = today.diff(startOfMonth, timeUnit);
          break;
        case 'resourceTimelineYear':
          timeUnit = 'month';
          difference = today.diff(dayjs().startOf('year'), timeUnit);
          break;
        default:
          timeUnit = 'month';
          difference = today.diff(dayjs().startOf('year'), timeUnit);
          break;
      }

      calendarApi.scrollToTime({ [timeUnit]: difference });
    }
  };

  return (
    <div>
      <div ref={checkboxFiltersRef}>
        <GanttFilter
          ganttFilter={ganttFilter}
          type={type}
          setType={setType}
          submenuNames={submenuNames}
          setStatusDialogOpen={setStatusDialogOpen}
        />
      </div>
      <div className="employeeCalendar">
        <FullCalendar
          ref={calendarRef}
          customButtons={timeIntervalButtons}
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          initialView={ganttFilter?.timeTableView ?? 'resourceTimelineMonth'}
          resources={tasks.resources}
          resourceOrder="visOrder"
          locales={[huLocale]}
          locale="hu"
          slotLabelFormat={[
            { month: 'long', year: 'numeric', weekday: 'long' },
            { hour: 'numeric', minute: '2-digit' },
          ]}
          datesSet={handleDatesSet}
          events={[...tasks.taskEvents, ...tasks.backgroundEvents]}
          editable={false}
          nowIndicator={true}
          eventClick={(clickInfo) => {
            if (clickInfo.event.display !== 'background') {
            }
          }}
          eventContent={(eventInfo) => {
            if (eventInfo.event.display !== 'background') {
              return <EventChip eventInfo={eventInfo} />;
            }
          }}
          headerToolbar={{
            left: `prev,next,today ${intervalButtons}`,
            center: 'title',
            right: 'resourceTimelineMonth,resourceTimelineYear',
          }}
          views={calendarViews}
          slotDuration={timeInterval}
          resourceAreaWidth="15%"
          resourceAreaHeaderContent="Erőforrás"
          scrollTimeReset={false}
          resourceLabelClassNames={(data) => {
            if (!data.resource._resource.parentId) {
              return ['fc-res-group'];
            }
          }}
        />
      </div>
      <TypeDialog
        open={statusDialogOpen}
        saveView={saveView}
        submenu={submenu}
        filter={{ filterVal, setFilterVal }}
        handleClose={() => {
          setStatusDialogOpen(false);
        }}
      />
    </div>
  );
};
