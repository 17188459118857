import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI, SelectUI } from '../Interface';
import { TaskReportOptions } from '../../config/taskReport';

const FormFields = ({ selectedTask, values, validateField, setFieldValue, errors, autocompleteOptions, user }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4">
        <Field
          type="autocomplete"
          name="simpleTaskId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Feladat" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.simpleTaskId} />}
          onChange={(_e, newVal) => {
            setFieldValue('simpleTaskId', newVal?.value ?? null).then(() => {
              validateField('simpleTaskId');
            });
          }}
          InputLabelProps={{ shrink: values.simpleTaskId !== '' }}
          selectedValue={values.simpleTaskId}
          selectedLabelValue={selectedTask?.taskType?.simpleTaskId}
          table={['simpleTask']}
          listType={{ id: 'simpleTaskId', name: 'simpleTaskName' }}
          joins={'simpleTask.simpleTaskId'}
          conditions={[`simpleTask.archive = 'N'`]}
          getOptionLabel={(option) => {
            const paddedId = option.value.toString().padStart(5, '0');
            return `${option.label} (F-${paddedId})`;
          }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="timeSpent"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Ráfordított idő" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.timeSpent} />}
          InputLabelProps={{ shrink: values.timeSpent !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          name="me"
          component={SelectUI}
          fullWidth
          option={TaskReportOptions}
          value={TaskReportOptions[0].value}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('me', value ?? null).then(() => {
              validateField('me');
            });
          }}
          label={<FormLabelUI text="ME" />}
          helperText={<FormErrorUI message={errors.me} />}
        />
      </div>
      <div className="col-span-4">
        <Field
          type="autocomplete"
          name="typeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Feladat típusa" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.typeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('typeId', newVal?.value ?? null).then(() => {
              validateField('typeId');
            });
          }}
          InputLabelProps={{ shrink: values.typeId !== '' }}
          selectedValue={values.typeId}
          selectedLabelValue={selectedTask?.taskType?.typeId}
          dataset={autocompleteOptions.type}
        />
      </div>
      <div className="col-span-4">
        <Field
          type="text"
          disabled
          name="createdBy"
          as={InputUI}
          fullWidth
          value={user.userName}
          label={<FormLabelUI text="Dolgozó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.createdBy} />}
          InputLabelProps={{ shrink: values.createdBy !== '' }}
        />
      </div>
    </div>
  );
};

export default FormFields;
