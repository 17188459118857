import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'projectV2';

const projectV2Service = {
  getProjects: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projects`,
      data,
    });
  },

  getProjectById: (projectId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${projectId}`,
    });
  },

  createProject: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProject: (data, projectId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${projectId}`,
      data,
    });
  },

  deleteProject: (data, projectId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${projectId}`,
      data,
    });
  },

  orderProject: (projectId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderProject/${projectId}`,
      data,
    });
  },

  uploadFile: (file, id, userId) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('userId', userId);

    return request({
      method: Methods.POST,
      resource: `${baseURL}/uploadFile/${id}`,
      formData: true,
      data: formData,
    });
  },

  deleteFile: (id, userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteFile/${id}`,
      data: { userId },
    });
  },
};

export default projectV2Service;
