import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'production';

const productionService = {
  getProductions: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productions`,
      data,
    });
  },

  createProduction: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProduction: (data, productionId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${productionId}`,
      data,
    });
  },

  deleteProduction: (data, productionId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${productionId}`,
      data,
    });
  },

  getProductionInfo: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreport/search`,
      data,
    });
  },

  createProductionReport: (prodOperationId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreport/${prodOperationId}`,
      data,
    });
  },

  getProductionReports: (prodOperationId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreports/${prodOperationId}`,
      data,
    });
  },

  getProductionById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${id}`,
    });
  },
};

export default productionService;
