import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'complaint';

const complaintService = {
  getComplaints: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/complaints`,
      data,
    });
  },

  createComplaint: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateComplaint: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteComplaint: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  getItems: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/complaints/items`,
    });
  },

  getBusinessPartners: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/complaints/businessPartners`,
    });
  },

  uploadFile: (file, id, userId) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('userId', userId);

    return request({
      method: Methods.POST,
      resource: `${baseURL}/uploadFile/${id}`,
      formData: true,
      data: formData,
    });
  },

  deleteFile: (id, userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteFile/${id}`,
      data: { userId },
    });
  },
};

export default complaintService;
