import React, { useState, useEffect } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import sourcelistService from '../../service/sourcelist.service';

const SourceListReports = ({ refetch, values, viewField, operatorsDisabled, rowSelection, autocompleteOptions }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'itemCode', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    sourcelistService.getSourcelists(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'itemCode',
      type: 'singleSelect',
      headerName: 'Cikkszám',
      valueOptions: autocompleteOptions.items,
    },
    {
      field: 'itemName',
      type: 'string',
      headerName: 'Cikknév',
      filterable: false,
      renderCell: (data) => {
        return data.row.item?.itemName;
      },
    },
    {
      field: 'baseQuantity',
      type: 'number',
      headerName: 'Mennyiség',
    },
    {
      field: 'quantityUnitId',
      type: 'string',
      headerName: 'Me.',
      filterable: false,
      renderCell: (data) => {
        return data.row.item.quantityUnit?.quantityUnit;
      },
    },
  ]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.sourceList}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      getRowId={(row) => row.itemCode}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};

export default SourceListReports;
