import React from 'react';
import { Field } from 'formik';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, TimePickerUi, FormErrorUI, FormLabelUI } from '../Interface';

const CrmTaskFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  autoCompleteOptions,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mx-4">
      <div className="col-span-3">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Esemény rövid neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>
      <div className="col-span-1">
        <Field
          type="text"
          name="prefixedId"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Azonosító" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.prefixedId} />}
          disabled
          InputLabelProps={{ shrink: values.prefixedId !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1 ">
        <Field
          type="date"
          name="startDate"
          component={DatePickerUI}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="time"
          name="startDate"
          component={TimePickerUi}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő időpont" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="date"
          name="endDate"
          component={DatePickerUI}
          value={values.endDate}
          fullWidth
          label={<FormLabelUI text="Befejező dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.endDate} />}
          onChange={(date) => {
            setFieldValue('endDate', date).then(() => {
              validateField('endDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.endDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="duration"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Időintervallum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.duration} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.duration !== '' }}
          onChange={(e) => {
            const newendDate = values.startDate.add(e.target.value, 'hour');
            setFieldValue('duration', e.target.value ?? '');
            setFieldValue('endDate', newendDate).then(() => {
              validateField('endDate');
            });
          }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="projectId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Projekt" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectId} />}
          onChange={(_e, newVal) => {
            setFieldValue('projectId', newVal?.value ?? null).then(() => {
              validateField('projectId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectId !== '' }}
          selectedValue={values.projectId}
          selectedLabelValue={selectedValues?.project?.projectName}
          dataset={autoCompleteOptions.project}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="typeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Esemény típusa" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.typeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('typeId', newVal?.value ?? null).then(() => {
              validateField('typeId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.typeId !== '' }}
          selectedValue={values.typeId}
          selectedLabelValue={selectedValues?.type?.itemName}
          dataset={autoCompleteOptions.type}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          dataset={autoCompleteOptions.status}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="responsiblePersonId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Felelős" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.responsiblePersonId} />}
          onChange={(_e, newVal) => {
            setFieldValue('responsiblePersonId', newVal?.value ?? null).then(() => {
              validateField('responsiblePersonId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.responsiblePersonId !== '' }}
          selectedValue={values.responsiblePersonId}
          selectedLabelValue={selectedValues?.responsiblePerson?.userName}
          dataset={autoCompleteOptions.user}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="parentName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Szülő" />}
          variant="standard"
          disabled
          InputLabelProps={{ shrink: values.parentName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="createdBy"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Tulajdonos" />}
          variant="standard"
          disabled
          InputLabelProps={{ shrink: values.createdBy !== '' }}
          selectedValue={values.createdBy}
          selectedLabelValue={selectedValues?.user?.userName}
          dataset={autoCompleteOptions.user}
        />
      </div>
    </div>
  );
};

export default CrmTaskFields;
