import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const TemplateItemTable = ({ items }) => {
  return (
    <TableContainer component={Paper} className="h-[550px] custom-scrollbar">
      <Table>
        <TableHead className="bg-tableHeaderColor">
          <TableRow>
            <TableCell style={{ width: '600px' }}>Kiválasztott template elemei</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-body': {
              padding: '10px 0px 10px 15px',
            },
          }}
        >
          {items.map((item, index) => (
            <TableRow key={item.id} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
              <TableCell>{item.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TemplateItemTable;
