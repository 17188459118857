import React, { useEffect, useState } from 'react';
import { Field, FieldArray } from 'formik';
import { Delete, Add } from '@mui/icons-material';
import { Typography } from '@material-tailwind/react';

import {
  BiFieldTypeList,
  BiReportType,
  BiFilterFieldTypeList,
  BiAnalysisTypeList,
  BiFieldType,
} from '../../../config/bi_report';
import {
  InputUI,
  FormErrorUI,
  FormLabelUI,
  AutoCompleteSelectUI,
  ButtonUI,
  SelectUI,
  CheckboxUI,
} from '../../Interface';

const BiFieldForm = ({
  values, //formikcontext
  validateField, //formikcontext
  setFieldValue, //formikcontext
  errors, //formikcontext
  defaultField,
  autocompleteData,
  fieldErrorState,
}) => {
  const { fieldErrors } = fieldErrorState;

  const [reportFields, setReportFields] = useState([]);
  const [groupFields, setGroupFields] = useState([]);
  const [filterFields, setFilterFields] = useState([]);

  useEffect(() => {
    if (values.biField) {
      const { grpFields, rptFields, filterFields } = values.biField.reduce(
        (acc, item, index) => {
          const itemWithIndex = { index, ...item };
          if (item.isGroup) {
            acc.grpFields.push(itemWithIndex);
          } else if (item.isFilter) {
            acc.filterFields.push(itemWithIndex);
          } else {
            acc.rptFields.push(itemWithIndex);
          }
          return acc;
        },
        { grpFields: [], rptFields: [], filterFields: [] }
      );
      setReportFields(rptFields);
      setGroupFields(grpFields);
      setFilterFields(filterFields);
    }
  }, [values.biField]);

  return (
    <FieldArray name="biField">
      {({ remove, push }) => (
        <>
          <div className="grid grid-cols-3 gap-x-4">
            <div className="col-span-1 flex items-center mx-4">
              <Typography variant="h6" className="mr-2">
                Mezők
              </Typography>
              {values.reportType !== BiReportType.PIE && values.reportType !== BiReportType.KPI && (
                <ButtonUI
                  text={<Add fontSize="small" />}
                  className="bg-success p-0.5"
                  type="button"
                  onClick={() => {
                    push(defaultField);
                  }}
                  disabled={!(values.biHeadId && values.reportType)}
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-4 mx-2 mb-2 justify-items-center items-center">
            {fieldErrors.length > 0 &&
              fieldErrors.map((error) => (
                <div className="col-span-1" key={error.id}>
                  <FormErrorUI message={error.message} />
                </div>
              ))}
          </div>
          <div>
            {reportFields.length > 0 &&
              reportFields.map((biField) => (
                <div className="grid grid-cols-12 gap-x-4 mx-4 mb-5" key={biField.index}>
                  <div className="col-span-3 ">
                    <Field
                      type="autocomplete"
                      name={`biField.${biField.index}.field`}
                      component={AutoCompleteSelectUI}
                      fullWidth
                      label={<FormLabelUI text="Mező" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.biField?.[biField.index]?.field} />}
                      inputLabelProps={{ shrink: biField.field !== null }}
                      onChange={(_e, newVal) => {
                        setFieldValue(`biField.${biField.index}.field`, newVal?.value ?? null).then(() => {
                          validateField(`biField.${biField.index}.field`);
                        });
                      }}
                      //@TODO ezt ki kell vizsgálni!!!
                      value={biField.field}
                      dataset={autocompleteData.biField}
                      isOptionEqualToValue={(option, value) => option.value === value}
                    />
                  </div>
                  {values.reportType === BiReportType.TABLE && (
                    <>
                      <div className="col-span-2 ">
                        <Field
                          name={`biField.${biField.index}.type`}
                          component={SelectUI}
                          fullWidth
                          option={BiFieldTypeList}
                          value={biField.type ?? ''}
                          variant="standard"
                          onChange={(e) => {
                            setFieldValue(`biField.${biField.index}.type`, e.target.value ?? null).then(() => {
                              validateField(`biField.${biField.index}.type`);
                            });
                          }}
                          label={<FormLabelUI text="Mező típus" />}
                          helperText={<FormErrorUI message={errors.biField?.[biField.index]?.type} />}
                          InputLabelProps={{ shrink: values.biField?.[biField.index]?.type !== '' }}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-span-3 ">
                    <Field
                      type="text"
                      name={`biField.${biField.index}.name`}
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Mező név" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.biField?.[biField.index]?.name} />}
                      InputLabelProps={{ shrink: values.biField?.[biField.index]?.name !== '' }}
                    />
                  </div>

                  {values.reportType !== BiReportType.TABLE && (
                    <>
                      <div className="col-span-3 ">
                        <Field
                          name={`biField.${biField.index}.analysis`}
                          component={SelectUI}
                          fullWidth
                          option={BiAnalysisTypeList}
                          value={values.biField?.[biField.index]?.analysis ?? ''}
                          variant="standard"
                          onChange={(e) => {
                            setFieldValue(`biField.${biField.index}.analysis`, e.target.value ?? null).then(() => {
                              validateField(`biField.${biField.index}.analysis`);
                            });
                          }}
                          label={<FormLabelUI text="Analízis" />}
                          helperText={<FormErrorUI message={errors.biField?.[biField.index]?.analysis} />}
                          InputLabelProps={{ shrink: values.biField?.[biField.index]?.analysis }}
                        />
                      </div>
                    </>
                  )}
                  {(biField.type === BiFieldType.NUMBER ||
                    biField.type === BiFieldType.CURRENCY ||
                    values.reportType === BiReportType.KPI) && (
                    <>
                      <div className="col-span-1 ">
                        <Field
                          type="number"
                          name={`biField.${biField.index}.thresholdMin`}
                          as={InputUI}
                          fullWidth
                          label={<FormLabelUI text="Alsó határérték" />}
                          variant="standard"
                          helperText={<FormErrorUI message={errors.biField?.[biField.index]?.thresholdMin} />}
                          InputLabelProps={{
                            shrink:
                              values.biField?.[biField.index]?.thresholdMin !== '' &&
                              values.biField?.[biField.index]?.thresholdMin !== null,
                          }}
                        />
                      </div>
                      <div className="col-span-1 ">
                        <Field
                          type="number"
                          name={`biField.${biField.index}.thresholdMax`}
                          as={InputUI}
                          fullWidth
                          label={<FormLabelUI text="Felső határérték" />}
                          variant="standard"
                          helperText={<FormErrorUI message={errors.biField?.[biField.index]?.thresholdMax} />}
                          InputLabelProps={{
                            shrink:
                              values.biField?.[biField.index]?.thresholdMax !== '' &&
                              values.biField?.[biField.index]?.thresholdMax !== null,
                          }}
                        />
                      </div>
                      <div className="col-span-1 ">
                        <Field
                          name={`biField.${biField.index}.thresholdArrow`}
                          type="checkbox"
                          as={CheckboxUI}
                          fullWidth
                          label={<FormLabelUI text="Nyíl" />}
                          checked={values.biField?.[biField.index]?.thresholdArrow}
                          onChange={async (e) => {
                            setFieldValue(`biField.${biField.index}.thresholdArrow`, e.target.checked).then(() => {
                              validateField(`biField.${biField.index}.thresholdArrow`);
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                  {values.reportType !== BiReportType.PIE && values.reportType !== BiReportType.KPI && (
                    <div className="col-span-1 flex justify-center">
                      <button type="button" onClick={() => remove(biField.index)} className="text-red-600 items-center">
                        <Delete />
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>

          {groupFields.length > 0 && (
            <>
              <Typography variant="h6" className="grid grid-cols-1 gap-x-4 mx-4 ">
                Csoportosítás
              </Typography>
              {groupFields.map((biField) => (
                <div className="grid grid-cols-10 gap-x-4 mx-4 mb-5 md:max-w-[75%] xl:max-w-[65%]" key={biField.index}>
                  <div className="col-span-3 ">
                    <Field
                      type="autocomplete"
                      name={`biField.${biField.index}.field`}
                      component={AutoCompleteSelectUI}
                      fullWidth
                      label={<FormLabelUI text="Mező" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.biField?.[biField.index]?.field} />}
                      inputLabelProps={{ shrink: biField.field !== null }}
                      onChange={(_e, newVal) => {
                        setFieldValue(`biField.${biField.index}.field`, newVal?.value ?? null).then(() => {
                          validateField(`biField.${biField.index}.field`);
                        });
                      }}
                      //@TODO ezt ki kell vizsgálni!!!
                      value={biField.field}
                      dataset={autocompleteData.biField}
                      isOptionEqualToValue={(option, value) => option.value === value}
                    />
                  </div>

                  <div className="col-span-3 ">
                    <Field
                      type="text"
                      name={`biField.${biField.index}.name`}
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Csoport név" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.biField?.[biField.index]?.name} />}
                      InputLabelProps={{ shrink: values.biField?.[biField.index]?.name !== '' }}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
          {values.reportType !== BiReportType.TABLE && (
            <>
              <div className="col-span-1 flex items-center mx-4">
                <Typography variant="h6" className="mr-2">
                  Szűrő
                </Typography>

                <ButtonUI
                  text={<Add fontSize="small" />}
                  className="bg-success p-0.5"
                  type="button"
                  onClick={() => {
                    const newFilterField = { ...defaultField, isFilter: true };
                    push(newFilterField);
                  }}
                  disabled={!(values.biHeadId && values.reportType)}
                />
              </div>
              {filterFields.length > 0 && (
                <>
                  {filterFields.map((biField) => (
                    <div
                      className="grid grid-cols-10 gap-x-4 mx-4 mb-5 md:max-w-[75%] xl:max-w-[65%]"
                      key={biField.index}
                    >
                      <div className="col-span-3 ">
                        <Field
                          type="autocomplete"
                          name={`biField.${biField.index}.field`}
                          component={AutoCompleteSelectUI}
                          fullWidth
                          label={<FormLabelUI text="Mező" />}
                          variant="standard"
                          helperText={<FormErrorUI message={errors.biField?.[biField.index]?.field} />}
                          inputLabelProps={{ shrink: biField.field !== null }}
                          onChange={(_e, newVal) => {
                            setFieldValue(`biField.${biField.index}.field`, newVal?.value ?? null).then(() => {
                              validateField(`biField.${biField.index}.field`);
                            });
                          }}
                          //@TODO ezt ki kell vizsgálni!!!
                          value={biField.field}
                          dataset={autocompleteData.biField}
                          isOptionEqualToValue={(option, value) => option.value === value}
                        />
                      </div>
                      <div className="col-span-1 ">
                        <Field
                          name={`biField.${biField.index}.filterType`}
                          component={SelectUI}
                          fullWidth
                          option={BiFilterFieldTypeList}
                          value={biField.filterType ?? ''}
                          variant="standard"
                          onChange={(e) => {
                            setFieldValue(`biField.${biField.index}.filterType`, e.target.value ?? null).then(() => {
                              validateField(`biField.${biField.index}.filterType`);
                            });
                          }}
                          label={<FormLabelUI text="Szűrő típus" />}
                          helperText={<FormErrorUI message={errors.biField?.[biField.index]?.filterType} />}
                          InputLabelProps={{ shrink: values.biField?.[biField.index]?.filterType !== '' }}
                        />
                      </div>
                      <div className="col-span-3 ">
                        <Field
                          multiple
                          freeSolo
                          options={[]}
                          type="autocomplete"
                          name={`biField.${biField.index}.filterFieldValue`}
                          component={AutoCompleteSelectUI}
                          fullWidth
                          label={<FormLabelUI text="Szűrő érték" />}
                          variant="standard"
                          helperText={<FormErrorUI message={errors.biField?.[biField.index]?.filterFieldValue} />}
                          inputLabelProps={{ shrink: biField.field !== null }}
                          onChange={(_e, newVal) => {
                            setFieldValue(`biField.${biField.index}.filterFieldValue`, newVal).then(() => {
                              validateField(`biField.${biField.index}.filterFieldValue`);
                            });
                          }}
                          value={biField.filterFieldValue}
                        />
                      </div>
                      <div className="col-span-1 flex justify-center">
                        <button
                          type="button"
                          onClick={() => remove(biField.index)}
                          className="text-red-600 items-center"
                        >
                          <Delete />
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </>
      )}
    </FieldArray>
  );
};

export default BiFieldForm;
