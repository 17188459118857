import React, { useEffect } from 'react';
import { FormGroup } from '@mui/material';
import useSaveView from '../../hooks/useSaveView';
import { ButtonUI, SelectUI } from '../Interface';
import { EmployeeGanttOptionType } from '../../config/gantt';
import usePages from '../../context/PageContext';
import { useNavigate } from 'react-router-dom';
import useUsers from '../../context/UsersContext';

const GanttFilter = ({ type, setType, submenuNames, setStatusDialogOpen }) => {
  const { getUser, user } = useUsers();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);
  const saveView = useSaveView(user);
  const { setPages } = usePages();

  const navigateToProjectResourcePlannerPage = () => {
    setPages({ subMenuName: submenuNames[0] });
    navigate('/app/projectResourcePlanner');
  };

  return (
    <FormGroup>
      <div className="flex items-center gap-5">
        <div className="w-[200px]">
          <SelectUI
            label={<span className="text-white text-lg">Szűrés</span>}
            option={EmployeeGanttOptionType}
            value={type}
            inputProps={{
              sx: {
                color: 'white',
              },
            }}
            onChange={(e) => {
              const value = e.target.value;
              setType(value);
              saveView('ganttFilter', { selectedEmployeeValue: value });
            }}
          />
        </div>
        <div className="gap-1 flex items-center">
          <ButtonUI
            text="Típusok"
            className="bg-white text-black px-2 py-2.5 -ml-2 hover:bg-ganttHoverButtonColor hover:text-white"
            onClick={() => setStatusDialogOpen(true)}
          />
          <ButtonUI
            text={submenuNames[0]}
            className="bg-white text-black px-2 py-2.5 hover:bg-ganttHoverButtonColor hover:text-white"
            onClick={navigateToProjectResourcePlannerPage}
          />
        </div>
      </div>
    </FormGroup>
  );
};

export default GanttFilter;
