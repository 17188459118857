import React, { useState, useEffect } from 'react';
import useUsers from '../../context/UsersContext';
import FormFields from './FormFields';
import SimpleTaskTabs from './SimpleTaskTabs';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, DialogUI, ButtonUI } from '../Interface';
import Yup from '../../utils/yup';
import { TaskReportForm } from '../TaskReport/TaskReportForm';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { Formik, Form } from 'formik';
import simpletaskService from '../../service/simpletask.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

export const SimpleTaskForm = ({ simpleTaskState, dialog }) => {
  const {
    setRefetchTable,
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
    autocompleteData,
    autocompleteOptions,
  } = simpleTaskState;

  const { user } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  const [defaultValues, setDefaultValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  const defaultRespPerson = {
    id: null,
    isNew: true,
    userId: null,
    roleName: '',
  };

  const def = {
    simpleTaskName: '',
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()).add(1, 'hour'),
    duration: 1,
    preparedness: '',
    machineId: null,
    projectId: null,
    simpleTaskTypeId: null,
    userId: null,
    resposiblePersonId: null,
    statusId: null,
    priorId: null,
    simpleTaskDescription: '',
    companyLocationId: null,
    resourceOneId: null,
    resourceTwoId: null,
    resourceThreeId: null,
    simpleTaskRespPersonlist: [],
  };

  useEffect(() => {
    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          simpleTaskTypeId: autocompleteData?.type?.defaultValue ?? null,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          priorId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
          status: autocompleteData?.status?.defItem,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  const validationSchema = Yup.object().shape({
    simpleTaskName: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    duration: Yup.number().positive(),
    preparedness: Yup.number().nullable().positive(),
    machineId: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    simpleTaskTypeId: Yup.string().required(),
    userId: Yup.string().required(),
    resposiblePersonId: Yup.string().nullable(),
    statusId: Yup.string().required(),
    priorId: Yup.string().required(),
    simpleTaskDescription: Yup.string(),
    companyLocationId: Yup.string().nullable(),
    resourceOneId: Yup.string().nullable(),
    resourceTwoId: Yup.string().nullable(),
    resourceThreeId: Yup.string().nullable(),
    simpleTaskRespPersonlist: Yup.array().of(
      Yup.object().shape({
        userId: Yup.string().required(),
        roleName: Yup.string().required(),
      })
    ),
  });

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField || !dialog.isUpdate) {
      handleInsertObject(values);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    if (dialog) {
      setViewField(true);
    }
  }, [dialog?.handleClose]);

  const valuesToForm = () => {
    const {
      simpleTaskName,
      startDate,
      endDate,
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
      companyLocationId,
      resourceOneId,
      resourceTwoId,
      resourceThreeId,
      simpleTaskRespPersonlist,
    } = selectedValues;

    setInitValues({
      simpleTaskName,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      duration,
      preparedness: preparedness ?? defaultValues.preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
      companyLocationId,
      resourceOneId,
      resourceTwoId,
      resourceThreeId,
      simpleTaskRespPersonlist: simpleTaskRespPersonlist?.map((rp) => ({
        id: rp.respPersonListId,
        respPersonListId: rp.respPersonListId,
        userId: rp.userId,
        roleName: rp.roleName,
      })),
    });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseInt(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      updatedBy: user.userId,
    };

    const simpleTaskId = selectedValues.simpleTaskId;

    simpletaskService.updateSimpleTask(updData, simpleTaskId).then((_simpleTask) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseInt(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      createdBy: user.userId,
    };

    simpletaskService.createSimpleTask(insertObject).then((data) => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setAddField(true);
      setViewField(true);
      setInitValues({});
      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
      deletedAt: dayjs(new Date()),
    };
    simpletaskService.deleteSimpleTask(deleteData, selectedValues.simpleTaskId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleAddReport = (newReport) => {
    newReport.taskType = selectedValues.taskType;
    setSelectedValues((prev) => ({
      ...prev,
      taskReports: [...prev.taskReports, ...[newReport]],
    }));
    setRefetchTable(true);
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  const onReportClick = () => {
    setShowDialog(true);
  };

  return (
    <>
      <div className="h-full overflow-auto custom-scrollbar">
        <div className="flex items-center flex-wrap">
          <FormHeaderUI
            addClick={(!dialog || !dialog.isViewMode) && (!dialog ? onAddClick : null)}
            addDisabled={dialog?.isViewMode || !viewField || !canEdit}
            modClick={
              (!dialog || !dialog.isViewMode || dialog.isUpdate) && (!dialog || dialog.isUpdate ? onModifyClick : null)
            }
            modDisabled={dialog?.isViewMode || !viewField || operatorsDisabled || !canEdit}
            deleteClick={
              (!dialog || !dialog.isViewMode || dialog.isUpdate) && (!dialog || dialog.isUpdate ? onDeleteClick : null)
            }
            deleteDisabled={dialog?.isViewMode || !viewField || operatorsDisabled || !canEdit}
            copyClick={
              (!dialog || !dialog.isViewMode || dialog.isUpdate) && (!dialog || dialog.isUpdate ? onCopyClick : null)
            }
            copyDisabled={dialog?.isViewMode || !viewField || operatorsDisabled || !canEdit}
          />
          {!dialog && (
            <ButtonUI
              text="Lejelentés"
              size="sm"
              className="bg-labelColor py-3"
              onClick={onReportClick}
              disabled={!viewField || operatorsDisabled}
            />
          )}
        </div>

        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ resetForm }) => (
            <Form>
              <FormFields
                viewField={dialog?.isViewMode || ((!dialog || dialog?.isUpdate) && viewField)}
                submenuId={submenu}
                selectedValues={selectedValues}
                params={params}
                submenuNames={submenuNames}
                autocompleteOptions={autocompleteOptions}
              />

              <SimpleTaskTabs
                disabled={dialog?.isViewMode || ((!dialog || dialog?.isUpdate) && viewField)}
                setRefetchTable={setRefetchTable}
                addField={(!dialog || dialog?.isUpdate) && addField}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                user={user}
                autocompleteData={autocompleteData}
                autocompleteOptions={autocompleteOptions}
                defaultField={defaultRespPerson}
              />

              <FormButtonPanelUI
                onCancelClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setAddField(true);
                  resetForm();
                  if (dialog) {
                    dialog.handleClose();
                  }
                }}
                disabled={
                  dialog
                    ? dialog.isViewMode ||
                      (dialog.isUpdate && ((editButtonVisible && addField) || (!editButtonVisible && !addField)))
                    : (editButtonVisible && addField) || (!editButtonVisible && !addField)
                }
              />
            </Form>
          )}
        </Formik>
      </div>

      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />

      <DialogUI
        open={showDialog}
        headerContent={'Feladat lejelentő'}
        fullWidth={true}
        onClose={() => setShowDialog(false)}
      >
        <TaskReportForm selectedTask={selectedValues} setShowDialog={setShowDialog} onReportAdd={handleAddReport} />
      </DialogUI>
    </>
  );
};
