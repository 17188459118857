import { DialogUI } from '../Interface';
import { useEffect, useState } from 'react';
import { useCrmTask } from '../../hooks/useCrmTask';
import { CrmTaskPage } from '../CrmTask/CrmTaskPage';

const CalendarDialog = ({ open, handleClose, changed, data }) => {
  const { dataChanged, setDataChanged } = changed;
  const crmTaskState = useCrmTask();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (data) {
      crmTaskState.setSelectedValues(data);
      setIsUpdate(true);
    }
  }, [data, crmTaskState]);

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={data?.name}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <CrmTaskPage
          dialog={{
            handleClose: handleClose,
            dataChanged: dataChanged,
            setDataChanged: setDataChanged,
            isUpdate: isUpdate,
          }}
          crmTaskState={crmTaskState}
        />
      </div>
    </DialogUI>
  );
};

export default CalendarDialog;
