import React, { useEffect } from 'react';
import { Field } from 'formik';

import { FormErrorUI, FormLabelUI, AutoCompleteSelectUI, DatePickerUI, InputUI } from '../Interface';
import attendenceService from '../../service/attendence.service';

const AttendanceFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  selectedRow,
  autocompleteOptions,
}) => {
  useEffect(() => {
    getOrderNumber();
  }, [values]);

  const getOrderNumber = async () => {
    if (values.projectId) {
      const orderNumber = await attendenceService.getOrderNumber(values.projectId);
      const sanitizedOrderNumber = typeof orderNumber === 'string' ? orderNumber : '';
      setFieldValue('orderNumber', sanitizedOrderNumber);
    } else {
      setFieldValue('orderNumber', '');
    }
  };

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="date"
          component={DatePickerUI}
          value={values.date}
          fullWidth
          disabled={viewField}
          label={<FormLabelUI text="Dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.date} />}
          onChange={(date) => {
            setFieldValue('date', date).then(() => {
              validateField('date');
            });
          }}
          InputLabelProps={{ shrink: values.date !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="projectId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Projekt" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectId} />}
          onChange={(_e, newVal) => {
            setFieldValue('projectId', newVal?.value ?? null).then(() => {
              validateField('projectId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectId !== '' }}
          selectedValue={values.projectId}
          selectedLabelValue={selectedValues?.project?.projectName}
          dataset={autocompleteOptions.project}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="orderNumber"
          component={InputUI}
          disabled
          fullWidth
          label={<FormLabelUI text="Rendelési szám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.orderNumber} />}
          InputLabelProps={{ shrink: values.orderNumber !== '' }}
          value={values.orderNumber}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField && selectedRow.id.length === 0}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          dataset={autocompleteOptions.status}
        />
      </div>
    </div>
  );
};

export default AttendanceFields;
