import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

import useMenus from '../../context/MenuContext';
import UserReports from './UserReports';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import userService from '../../service/user.service';
import { FormFields } from './FormFields';
import useUsers from '../../context/UsersContext';
import appService from '../../service/app.service';
import biService from '../../service/bi.service';
import dashboardService from '../../service/dashboard.service';

export const UserForm = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [submenu, setSubmenu] = useState(null);
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [autoCompleteData, setAutoCompleteData] = useState([]);

  useEffect(() => {
    const def = {
      userId: null,
      userName: '',
      password: '',
      roleId: null,
      nickName: '',
      activeId: 1,
      homePage: null,
    };

    setDefaultValues(def);
    if (!Object.keys(user).length) getUser();
    getMenus();
    getAllAutoCompleteData();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('user');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        valuesToForm();
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const getAllAutoCompleteData = () => {
    Promise.all([appService.getAllSubMenus(), biService.allBiReports(), dashboardService.allDashboards()])
      .then(([submenuData, biReportData, dashboardData]) => {
        //@TODO maybe roles
        const formattedSubmenus = submenuData.map((submenu) => ({
          label: submenu.subMenuName,
          value: submenu.url,
        }));
        const formattedBiReports = biReportData.map((report) => ({
          label: `R - ${report.name}`,
          value: `bi/biPage/${report.id}`,
        }));

        const formattedDashboards = dashboardData.map((dashboard) => ({
          label: `D - ${dashboard.name}`,
          value: `dashboard/${dashboard.id}`,
        }));

        const mergedData = [...formattedSubmenus, ...formattedBiReports, ...formattedDashboards];

        setAutoCompleteData(mergedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string().nullable(),
    userName: Yup.string().required(),
    password: Yup.string().required(),
    roleId: Yup.string().required(),
    nickName: Yup.string(),
    activeId: Yup.string().nullable(),
    homePage: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const { userId, userName, password, roleId, nickName, activeId, homePage } = selectedValues;

    setInitValues({
      userId,
      userName,
      password,
      roleId,
      nickName,
      activeId,
      homePage,
    });
  };

  const haveSelectedValues = () => {
    return Boolean(Object.keys(selectedValues).length);
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    userService.updateUser(updData, selectedValues.userId).then((_user) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setSelectedValues({});
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    userService
      .createUser(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues({});
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setViewField(true);
        setInitValues({});
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          addNotification({
            content: 'A felhasználónév foglalt!',
            type: NotificationType.ERROR,
          });
        } else {
          addNotification({
            content: 'Hiba történt!',
            type: NotificationType.ERROR,
          });
        }
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    userService.deleteUser(deleteData, selectedValues.userId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
      setInitValues({});
    });
  };

  const loadFormData = () => {
    if (haveSelectedValues()) {
      const { userName, ...rest } = selectedValues;
      setDefaultValues({
        ...defaultValues,
        ...rest,
        userName: '',
      });
      setInitValues({
        ...defaultValues,
        ...rest,
        userName: '',
      });
    } else {
      setDefaultValues(defaultValues);
      setInitValues({});
    }
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              loadFormData();
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  submenuId={submenu}
                  validateField={validateField}
                  autoCompleteData={autoCompleteData}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />

      <UserReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
