import { DialogUI } from '../Interface';
import { useProduction } from '../../hooks/useProduction';
import { useEffect, useState } from 'react';
import { ProductionPage } from '../Production/NewProduction/ProductionPage';

const ProductDialog = ({ open, handleClose, changed, production }) => {
  const { productionChanged, setProductionChanged } = changed;
  const productionState = useProduction();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (production) {
      productionState.setSelectedValues(production);
      setIsUpdate(true);
    }
  }, [production, productionState]);

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={'Gyártás adatok'}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <ProductionPage
          dialog={{
            handleClose: handleClose,
            productionChanged: productionChanged,
            setProductionChanged: setProductionChanged,
            isUpdate: isUpdate,
          }}
          productionState={productionState}
        />
      </div>
    </DialogUI>
  );
};

export default ProductDialog;
