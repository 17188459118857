export const BiReportType = {
  TABLE: 'TABLE',
  PIE: 'PIE',
  LINE: 'LINE',
  BAR: 'BAR',
  HORIZONTAL: 'HORIZONTAL',
  KPI: 'KPI',
};

export const BiReportTypeList = [
  { label: 'Táblázat', value: BiReportType.TABLE },
  { label: 'Kör', value: BiReportType.PIE },
  //{ label: 'Vonal', value: BiReportType.LINE },
  { label: 'Oszlop', value: BiReportType.BAR },
  { label: 'Vízszintes oszlop', value: BiReportType.HORIZONTAL },
  { label: 'KPI', value: BiReportType.KPI },
];

export const BiFieldType = {
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  TIME: 'TIME',
  HIDDEN: 'HIDDEN',
  CURRENCY: 'CURRENCY',
};

export const BiFieldTypeList = [
  { label: 'Szám', value: BiFieldType.NUMBER },
  { label: 'Szöveg', value: BiFieldType.STRING },
  { label: 'Dátum', value: BiFieldType.DATE },
  { label: 'Dátum, idő', value: BiFieldType.DATETIME },
  { label: 'Idő', value: BiFieldType.TIME },
  { label: 'Rejtett', value: BiFieldType.HIDDEN },
  { label: 'Pénznem', value: BiFieldType.CURRENCY },
];
export const BiAnalysisType = {
  COUNT: 'COUNT',
  SUM: 'SUM',
  AVG: 'AVG',
  MAX: 'MAX',
  MIN: 'MIN',
};

export const BiAnalysisTypeList = [
  { label: 'Számolt', value: BiAnalysisType.COUNT },
  { label: 'Összeg', value: BiAnalysisType.SUM },
  { label: 'Átlag', value: BiAnalysisType.AVG },
  { label: 'Maximum', value: BiAnalysisType.MAX },
  { label: 'Minimum', value: BiAnalysisType.MIN },
];

export const BiOrderType = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const BiOrderTypeList = [
  { label: 'Növekvő', value: BiOrderType.ASC },
  { label: 'Csökkenő', value: BiOrderType.DESC },
];

export const BiFilterFieldType = {
  LIKE: 'LIKE',
  GT: 'GT',
  GTE: 'GTE',
  LT: 'LT',
  LTE: 'LTE',
  EQ: 'EQ',
  NOTLIKE: 'NOTLIKE',
  NOTEQ: 'NOTEQ',
};

export const BiFilterFieldTypeList = [
  { label: 'LIKE', value: BiFilterFieldType.LIKE },
  { label: 'NOT LIKE', value: BiFilterFieldType.NOTLIKE },
  { label: '>', value: BiFilterFieldType.GT },
  { label: '>=', value: BiFilterFieldType.GTE },
  { label: '<', value: BiFilterFieldType.LT },
  { label: '<=', value: BiFilterFieldType.LTE },
  { label: '=', value: BiFilterFieldType.EQ },
  { label: '!=', value: BiFilterFieldType.NOTEQ },
];
