import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import biService from '../../../service/bi.service';

const KPIReport = ({ report, linkedReportIdState, activeLevelState, filterValsState, reportStatesState, height }) => {
  const [chartData, setChartData] = useState({});
  const [chartHeight, setChartHeight] = useState(600);

  const { activeLevel } = activeLevelState;
  const { filterVals } = filterValsState;
  const { linkedReportId, setLinkedReportId } = linkedReportIdState;
  const { reportStates, setReportStates } = reportStatesState;

  const getChartData = useCallback(() => {
    biService.getReportData(report.id, activeLevel, filterVals).then((data) => setChartData(data));
  }, [report.id, activeLevel, filterVals]);

  useEffect(() => {
    if (height) setChartHeight(height);
  }, [height]);

  useEffect(() => {
    const prevReport = reportStates[reportStates.length - 1];
    if (Object.keys(report).length > 0 && prevReport?.reportId !== report.id) {
      getChartData();
    }
  }, [report, getChartData, activeLevel, reportStates]);

  const KPIIcon = ({ value }) => {
    const field = report.biField[0];

    const threshold = {
      min: field.thresholdMin ? parseFloat(field.thresholdMin) : null,
      max: field.thresholdMax ? parseFloat(field.thresholdMax) : null,
      arrow: field.thresholdArrow,
    };

    const type = threshold.min !== null && threshold.max !== null ? 'interval' : threshold.min !== null ? 'from' : 'to';
    const val = parseFloat(value);
    const iconProps = {
      color: '',
      direction: '',
      good: false,
    };

    switch (type) {
      case 'interval':
        if (threshold.min <= val && val <= threshold.max) {
          iconProps.color = 'green';
          iconProps.direction = 'up';
          iconProps.good = true;
        } else {
          iconProps.color = 'red';
          iconProps.direction = 'down';
          iconProps.good = false;
        }
        break;
      case 'from':
        if (threshold.min <= val) {
          iconProps.color = 'green';
          iconProps.direction = 'up';
          iconProps.good = true;
        } else {
          iconProps.color = 'red';
          iconProps.direction = 'down';
          iconProps.good = false;
        }
        break;
      case 'to':
        if (threshold.max >= val) {
          iconProps.color = 'green';
          iconProps.direction = 'down';
          iconProps.good = true;
        } else {
          iconProps.color = 'red';
          iconProps.direction = 'up';
          iconProps.good = false;
        }
        break;
    }

    return field.thresholdMin || field.thresholdMax || field.thresholdArrow ? (
      threshold.arrow ? (
        iconProps.direction === 'up' ? (
          <ArrowDropUpIcon className="pb-1" sx={{ color: iconProps.color, fontSize: 80 }} />
        ) : (
          <ArrowDropDownIcon className="pb-1" sx={{ color: iconProps.color, fontSize: 80 }} />
        )
      ) : iconProps.good ? (
        <DoneIcon className="pb-1" sx={{ color: iconProps.color, fontSize: 70, ml: 1 }} />
      ) : (
        <ClearIcon className="pb-1" sx={{ color: iconProps.color, fontSize: 70, ml: 1 }} />
      )
    ) : (
      <></>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mx={4}
      mb={2}
      sx={{ height: `${chartHeight}px` }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'grey.100',
          marginTop: `55px`,
          color: '#607d8b',
        }}
      >
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            {report.name}
          </Typography>
          <Typography variant="h1" fontWeight="bold">
            {chartData.data}
            <KPIIcon value={chartData.data} />
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default KPIReport;
