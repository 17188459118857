import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DialogUI from '../Interface/DialogUI';
import historyService from '../../service/history.service';

const HistoryDialog = ({ formId, lineId, open, handleClose }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (formId && lineId && open) {
      getHistories();
    }
  }, [formId, lineId, open]);

  const getHistories = () => {
    historyService.getHistoriesById(formId, lineId).then((data) => {
      setHistory(data);
    });
  };

  const getModifiedFields = (oldValue, newValue) => {
    const oldJson = JSON.parse(oldValue);
    const newJson = JSON.parse(newValue);
    const modifiedFields = {};

    Object.keys(newJson).forEach((key) => {
      if (oldJson[key] !== undefined && oldJson[key] !== newJson[key]) {
        modifiedFields[key] = oldJson[key];
      }
    });

    return JSON.stringify(modifiedFields, null, 2);
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Módosítások áttekintése"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1100px',
          },
        },
      }}
    >
      <div className="max-h-[750px] overflow-y-scroll overflow-x-hidden custom-scrollbar mt-5">
        <table className="border w-full">
          <thead className="bg-tableHeaderColor">
            <tr className="grid grid-cols-6">
              <th className="border p-3 col-span-1">Módosította</th>
              <th className="border p-3 col-span-1">Módosítás dátuma</th>
              <th className="border p-3 col-span-2">Régi érték</th>
              <th className="border p-3 col-span-2">Új érték</th>
            </tr>
          </thead>
          <tbody>
            {history.map((row, index) => (
              <tr key={row.id} className={index % 2 === 0 ? 'bg-gray-100 grid grid-cols-6' : 'grid grid-cols-6'}>
                <td className="border py-1 col-span-1 text-center">{row.user.userName}</td>
                <td className="border py-1 col-span-1 text-center">
                  {dayjs(row.updatedAt).format('YYYY.MM.DD HH:mm')}
                </td>
                <td className="border py-1 col-span-2 overflow-hidden break-words whitespace-pre-line">
                  {getModifiedFields(row.oldValue, row.newValue)}
                </td>
                <td className="border py-1 col-span-2 overflow-hidden break-words whitespace-pre-line">
                  {JSON.stringify(JSON.parse(row.newValue), null, 2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DialogUI>
  );
};

export default HistoryDialog;
