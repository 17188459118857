import React, { useEffect, useState, useMemo, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { some, isEqual } from 'lodash';

import biService from '../../../service/bi.service';
import useUsers from '../../../context/UsersContext';
import { BiReportType } from '../../../config/bi_report';
import TableReport from './TableReport';
import PieReport from './PieReport';
import BarReport from './BarReport';
import ReportButtons from './ReportButtons';
import KPIReport from './KPIReport';

const BiReport = ({ reportId, reRender, height, dashboardData, filter }) => {
  const { getUser, user } = useUsers();

  const [refresh, setRefresh] = useState(false);
  const [report, setReport] = useState({});
  const [linkedReportId, setLinkedReportId] = useState(null);

  const [activeLevel, setActiveLevel] = useState(null);
  const [filterVals, setFilterVals] = useState({ filter: [] });
  const [reportStates, setReportStates] = useState([]);

  const getReport = useCallback(() => {
    return biService.getReport(reportId).then((data) => setReport(data));
  }, [reportId]);

  const getLinkedReport = useCallback((linkedReportId) => {
    biService.getReport(linkedReportId).then((data) => setReport(data));
  }, []);

  useEffect(() => {
    if (report?.id !== reportId) {
      getReport();
    }
    setFilterVals({ filter: [] });
    setActiveLevel(null);
    setReportStates([]);
    setLinkedReportId(null);

    if (!Object.keys(user).length) getUser();
  }, [reportId]);

  useEffect(() => {
    //rerender is outer refresh call

    if (reRender) {
      setRefresh(true);
    }
  }, [reRender]);

  useEffect(() => {
    if (refresh) {
      getReport();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (linkedReportId) {
      getLinkedReport(linkedReportId);
    } else {
      if (Object.keys(report).length > 0 && report.id !== linkedReportId) {
        getReport();
      }
    }
  }, [linkedReportId]);

  useEffect(() => {
    const repFilter = [];
    if (Object.keys(report).length > 0 && user) {
      if (filter?.businessPartner && report.bpField) {
        repFilter.push({
          field: report.bpField,
          value: filter?.businessPartner?.value,
          isDefault: true,
        });
      }

      if (filter?.year && report.yearField) {
        repFilter.push({
          field: report.yearField,
          value: filter.year,
          isDefault: true,
        });
      }

      if (filter?.quarter && report.querterField) {
        repFilter.push({
          field: report.querterField,
          value: filter.quarter,
          isDefault: true,
        });
      }

      if (filter?.month && report.monthField) {
        repFilter.push({
          field: report.monthField,
          value: filter.month,
          isDefault: true,
        });
      }

      if (filter?.interval?.from && report.dateInterValField) {
        repFilter.push({
          field: report.dateInterValField,
          value: filter?.interval?.from.format('YYYY-MM-DD'),
          gte: true,
          isDefault: true,
        });
      }

      if (filter?.interval?.to && report.dateInterValField) {
        repFilter.push({
          field: report.dateInterValField,
          value: filter?.interval?.to.format('YYYY-MM-DD'),
          lte: true,
          isDefault: true,
        });
      }
      //dashboardData
      if (filter?.responsible && report.responsibleField && dashboardData?.responsibleFilter) {
        repFilter.push({
          field: report.responsibleField,
          value: filter?.responsible?.value,
          isDefault: true,
        });
      }

      if (
        Object.keys(user.userSalesPerson ?? {}).length > 0 &&
        report.responsibleField &&
        !dashboardData?.responsibleFilter
      ) {
        repFilter.push({
          field: report.responsibleField,
          value: user.userSalesPerson.slpCode,
          isDefault: true,
        });
      }
    }

    if (!isEqual(filterVals.filter, repFilter)) {
      setFilterVals((prev) => ({ filter: [...prev.filter, ...repFilter] }));
    }
  }, [filter, report, dashboardData]);

  const Report = useMemo(
    () => () => {
      if (report?.reportType === BiReportType.TABLE) {
        return (
          <TableReport
            report={report}
            filterValsState={{ filterVals, setFilterVals }}
            height={height}
            setRefresh={setRefresh}
          />
        );
      } else if (report?.reportType === BiReportType.PIE) {
        return (
          <PieReport
            report={report}
            linkedReportIdState={{ linkedReportId, setLinkedReportId }}
            activeLevelState={{ activeLevel, setActiveLevel }}
            filterValsState={{ filterVals, setFilterVals }}
            reportStatesState={{ reportStates, setReportStates }}
            height={height}
          />
        );
      } else if (report?.reportType === BiReportType.BAR || report?.reportType === BiReportType.HORIZONTAL) {
        return (
          <BarReport
            report={report}
            linkedReportIdState={{ linkedReportId, setLinkedReportId }}
            activeLevelState={{ activeLevel, setActiveLevel }}
            filterValsState={{ filterVals, setFilterVals }}
            reportStatesState={{ reportStates, setReportStates }}
            height={height}
          />
        );
      } else if (report?.reportType === BiReportType.KPI) {
        return (
          <KPIReport
            report={report}
            linkedReportIdState={{ linkedReportId, setLinkedReportId }}
            activeLevelState={{ activeLevel, setActiveLevel }}
            filterValsState={{ filterVals, setFilterVals }}
            reportStatesState={{ reportStates, setReportStates }}
            height={height}
          />
        );
      } else {
        return (
          <div className="relative inset-0 bg-black bg-opacity-20 flex justify-center items-center h-[1200px] mt-[-50px]">
            {/*<CircularProgress size={70} />*/}
          </div>
        );
      }
    },
    [report, activeLevel, filterVals, linkedReportId, reportStates]
  );

  return (
    <div className="relative w-full">
      <ReportButtons
        report={report}
        fromDashBoard={Object.keys(dashboardData ?? {}).length > 0}
        filterValsState={{ filterVals, setFilterVals }}
        activeLevelState={{ activeLevel, setActiveLevel }}
        linkedReportIdState={{ linkedReportId, setLinkedReportId }}
        reportStatesState={{ reportStates, setReportStates }}
      />

      <Report />
    </div>
  );
};

export default BiReport;
