import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import useMenus from '../../context/MenuContext';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import PropertyGroupReports from './PropertyGroupReports';
import PropertyGroupFields from './PropertyGroupFields';
import appService from '../../service/app.service';
import propertyGroupService from '../../service/propertyGroup.service';
import useUsers from '../../context/UsersContext';

export const PropertyGroupPage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const defaultValues = { name: '', formId: '' };
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [autoCompleteData, setAutoCompleteData] = useState({
    submenus: [],
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    setInitValues(defaultValues);
    getMenus();
    getAllSubmenus();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('propertyGroup');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const valuesToForm = () => {
    const { name, formId } = selectedValues;

    setInitValues({
      name,
      formId,
    });
  };

  const getAllSubmenus = () => {
    appService.getAllSubMenus().then((items) => {
      const formattedItems = items.map((item) => ({
        label: item.subMenuName,
        value: item.subMenuId,
      }));
      setAutoCompleteData((prev) => ({
        ...prev,
        submenus: formattedItems,
      }));
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    formId: Yup.string().required(),
  });

  const handleInsertObject = (values, resetForm) => {
    showLoader();
    const insertObject = { ...values, createdBy: user.userId };

    propertyGroupService
      .createPropertyGroup(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);

        setRowSelectionModel(data.id);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values, resetForm);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };
    propertyGroupService.updatePropertyGroup(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    propertyGroupService.deletePropertyGroup(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, resetForm, setFieldValue, validateField }) => (
              <Form>
                <PropertyGroupFields
                  values={values}
                  errors={errors}
                  viewField={viewField}
                  autoCompleteData={autoCompleteData}
                  selectedValues={selectedValues}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                />
                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <PropertyGroupReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            autoCompleteData={autoCompleteData}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
