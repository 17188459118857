import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { dataGridColumns } from '../../utils/helper';

import { DataGridUI, DataGridIdentifiers, FormErrorUI, InputUI, AutoCompleteSelectUI, AddButtonUi } from '../Interface';

function EditToolbar(props) {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `attendanceUser.${values.attendanceUser.length ?? 0}.userId`,
      `attendanceUser.${values.attendanceUser.length ?? 0}.timeSpent`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
}

const AttendanceUsers = ({ disabled, defaultField, autocompleteOptions, params, selectedValues }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.attendanceUser.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [`attendanceUser.${valueIndex}.userId`, `attendanceUser.${valueIndex}.timeSpent`];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.attendanceUser?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'userId',
        headerName: 'Név',
        editable: true,
        sortable: false,
        flex: 1,
        renderCell: (data) => {
          const findedResourceOne = autocompleteOptions?.user.find((item) => item.value === data.row?.userId);
          return findedResourceOne?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedResourceOne = autocompleteOptions.user.find((item) => item.value === data.row.userId);

          return (
            <Field
              name={`attendanceUser.${valueIndex}.userId`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.attendanceUser?.[valueIndex]?.userId} />}
              selectedValue={values.attendanceUser?.[valueIndex]?.userId}
              selectedLabelValue={findedResourceOne?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`attendanceUser.${valueIndex}.userId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal.value ?? null,
                });
              }}
              dataset={autocompleteOptions.user}
              onBlur={async () => await validateField(`attendanceUser.${valueIndex}.userId`)}
            />
          );
        },
      },
      {
        field: 'timeSpent',
        headerName: 'Idő(óra)',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`attendanceUser.${valueIndex}.timeSpent`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.attendanceUser?.[valueIndex]?.timeSpent} />}
              onChange={(e) => {
                setFieldValue(`attendanceUser.${valueIndex}.timeSpent`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`attendanceUser.${valueIndex}.timeSpent`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  const param = params?.ATTENDANCE_USER_RED_LINE;

  const getRowBackgroundColor = (params) => {
    const attendanceUser = params.row;
    const projectResources = selectedValues?.project?.projectResourceOne || [];

    const isUserMissing = !projectResources.some((resource) => resource.resourceId === attendanceUser.userId);

    if (isUserMissing && param === 'Y') {
      return 'datagrid-line-background';
    }

    return '';
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="attendanceUser">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.AttendanceUsers}
                sx={{ height: 460 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.attendanceUser ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
                getRowClassName={(params) => {
                  return getRowBackgroundColor(params);
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default AttendanceUsers;
