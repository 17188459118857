import React, { useEffect, useState } from 'react';
import { ButtonUI, DialogUI } from '../../../Interface';
import TemplatesTable from './TemplatesTable';
import TemplateItemTable from './TemplateItemTable';

const TemplateImportDialog = ({ open, handleClose, templates, onItemAdd, checkListName, isNew }) => {
  const { name, setName } = checkListName;
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (selectedTemplate) {
      const selected = templates.find((template) => template.id === selectedTemplate);
      setItems(selected ? selected.checkListTemplateItem : []);
    } else {
      setItems([]);
    }
  }, [selectedTemplate]);

  const handleTemplateDoubleClick = (template) => {
    if (template && template.checkListTemplateItem) {
      onItemAdd(template.checkListTemplateItem);

      if (name === '') {
        setName(template.name);
      }
      handleClose();
    }
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      fullWidth
      headerContent="Template importálás"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '1200px',
          },
        },
      }}
    >
      <div className="text-labelColor mt-3">
        <div className="grid grid-cols-4 gap-5 items-end">
          <div className="col-span-2">
            <TemplateItemTable items={items} />
          </div>
          <div className="col-span-2">
            <TemplatesTable
              selected={{ selectedTemplate, setSelectedTemplate }}
              templates={templates}
              onTemplateDoubleClick={handleTemplateDoubleClick}
            />
          </div>
        </div>
        <div className="text-center mt-5">
          <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
        </div>
      </div>
    </DialogUI>
  );
};

export default TemplateImportDialog;
