import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';

import { dataGridPremiumColumns } from '../../utils/helper';
import {
  DataGridIdentifiers,
  FormErrorUI,
  AddButtonUi,
  AutoCompleteSelectUI,
  DatagridPremiumUi,
  InputUI,
} from '../Interface';
const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultItem } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultItem.id = uuidv4();
    defaultItem.isNew = true;
    await push(defaultItem);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultItem.id]: { mode: GridRowModes.Edit },
    }));

    await validateField(`sourceListItem.${values.sourceListItem.length ?? 0}.itemCode`);
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const SourceListItem = ({ disabled, defaultItem, autocompleteOptions }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.sourceListItem.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    await validateField(`sourceListItem.${valueIndex}.itemCode`);

    if (errors?.sourceListItem?.[valueIndex]?.message) {
      return;
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'itemCode',
        headerName: 'Cikk',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions.items.find(
            (u) => u.value === values.sourceListItem?.[valueIndex]?.itemCode
          );

          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions.items.find(
            (u) => u.value === values.sourceListItem?.[valueIndex]?.itemCode
          );
          return (
            <Field
              name={`sourceListItem.${valueIndex}.itemCode`}
              type="text"
              variant="standard"
              as={AutoCompleteSelectUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.sourceListItem?.[valueIndex]?.itemCode} />}
              onChange={(_e, newVal) => {
                setFieldValue(`sourceListItem.${valueIndex}.itemCode`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`sourceListItem.${valueIndex}.itemCode`)}
              value={findedItem}
              isOptionEqualToValue={(option, value) => option.value === value}
              dataset={autocompleteOptions.items}
            />
          );
        },
      },

      {
        field: 'neededQuantity',
        headerName: 'Szükséges mennyiség',
        type: 'string',
        editable: true,
        sortable: false,
        valueGetter: (_data, row) => {
          const valueIndex = getValueIndex(values, row.id);
          return values?.sourceListItem?.[valueIndex]?.neededQuantity;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`sourceListItem.${valueIndex}.neededQuantity`}
              type="number"
              as={InputUI}
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.sourceListItem?.[valueIndex]?.neededQuantity} />}
              onChange={(e) => {
                setFieldValue(`sourceListItem.${valueIndex}.neededQuantity`, e.target?.value);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target?.value,
                });
              }}
              onBlur={async () => await validateField(`sourceListItem.${valueIndex}.neededQuantity`)}
            />
          );
        },
      },

      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <div className="align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <FieldArray name="sourceListItem">
              {({ remove, push }) => (
                <DatagridPremiumUi
                  datagridIdentifier={DataGridIdentifiers.sourceListItem}
                  height={480}
                  columns={columns(setFieldValue, validateField, errors, remove)}
                  rows={values?.sourceListItem ?? []}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      disabled,
                      push,
                      defaultItem,
                      setRowModesModel,
                    },
                  }}
                  onCellDoubleClick={(_params, event) => {
                    event.defaultMuiPrevented = true;
                  }}
                  headerFilters={false}
                  getRowHeight={() => 'auto'}
                />
              )}
            </FieldArray>
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceListItem;
