import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';

const AddButtonUI = (props) => {
  return (
    <IconButton
      size="small"
      sx={{
        margin: '3px',
        color: 'white',
        padding: '1px',
        backgroundColor: '#7d9eb0',
        '&:hover': {
          backgroundColor: 'grey.400',
        },
      }}
      {...props}
    >
      <AddIcon fontSize="small" />
    </IconButton>
  );
};

export default AddButtonUI;
