import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'item';

const itemService = {
  getItems: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/items`,
      data,
    });
  },

  updateItem: (data, itemCode) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${itemCode}`,
      data,
    });
  },

  createItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteItem: (data, itemCode) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${itemCode}`,
      data,
    });
  },
};
export default itemService;
